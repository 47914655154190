<template>
  <ErrorLayout title="Unable to connect">
    <template #figure>
      <Figure404 />
    </template>
    <template #default>
      <p>Check your internet connection and then try one of the following:</p>
      <ul>
        <li>
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="router.go(0)">Refresh</a> the page
        </li>
        <li>
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="router.go(-1)">Go back</a>
        </li>
      </ul>
    </template>
  </ErrorLayout>
</template>

<script setup lang="ts">
  import { useRouter } from 'vue-router';

  import Figure404 from '@/assets/404.svg'; // preload so it can be displayed with no connection

  import SvgIcon from '@/shared/icons/SvgIcon.vue';

  import ArrowRightIcon from '@/shared/icons/svgs/arrow-right.svg';

  import ErrorLayout from '@/app/layout/views/ErrorLayout.vue';

  const router = useRouter();
</script>

<style lang="scss" scoped></style>
