import { Temporal } from '@js-temporal/polyfill';

import { DangerousGoodsPreset, dangerousGoodsPresetFactory } from '@/shared/models';
import DangerousGoodsPackingGroup from '@/shared/models/DangerousGoods/DangerousGoodsPackingGroup';
import {
  type DangerousGoodsPresetSummary,
  dangerousGoodsPresetSummaryFactory,
} from '@/shared/models/DangerousGoods/DangerousGoodsPresetSummary';
import { DangerousGoodsQuantityUnit } from '@/shared/models/DangerousGoods/DangerousGoodsQuantity';
import mapPaginatedResponse from '@/shared/services/api-client/helpers/mapPaginatedResponse';
import MappingError from '@/shared/services/errors/MappingError';
import { operations } from '@/shared/services/schema/geppetto-org/dangerousgoodspresets.schema';

/* eslint-disable no-restricted-imports */
import DGFormData from '@/app/modules/DangerousGoods/models/DGFormData';
import DGFormDataDeclarable from '@/app/modules/DangerousGoods/models/DGFormDataDeclarable';
// import DGFormDataNotifiable from '@/app/modules/DangerousGoods/models/DGFormDataNotifiable';

const isDeclarable = (formData: DGFormData): formData is DGFormDataDeclarable => formData.dgType === 'declarable';
// const isNotifiable = (formData: DGFormData): formData is DGFormDataNotifiable => formData.dgType === 'notifiable';

type GetPresetResponse = operations['viewDangerousGoodsPreset']['responses']['200']['content']['application/json'];
export const mapGetDangerousGoodsPresetResponseToDangerousGoodsPreset = (
  response: GetPresetResponse,
): DangerousGoodsPreset =>
  dangerousGoodsPresetFactory.create({
    id: response.data.id,
    siteId: response.data.siteId,
    notifiable: false,
    properShippingName: response.data.properShippingName,
    technicalName: response.data.technicalName,
    productDescription: response.data.productDescription,
    unNumber: response.data.unNumber,
    classOrDivision: response.data.classOrDivision,
    subsidiaryRisks: response.data.subsidiaryRisks,
    packingGroup: response.data.packingGroup as DangerousGoodsPackingGroup | undefined,
    receptacle: response.data.receptacle,
    receptacleAggregateQuantity: {
      amount: response.data.receptacleAggregateQuantity?.amount,
      unit: response.data.receptacleAggregateQuantity?.unit as DangerousGoodsQuantityUnit,
    },
    notification: undefined,
  });

type CreatePresetRequest = operations['createDangerousGoodsPreset']['requestBody']['content']['application/json'];
export const mapDGFormDataToCreatePresetRequest = (preset: DGFormData, siteId: string): CreatePresetRequest => {
  if (!siteId || !preset.properShippingName || !preset.unNumber || !preset.dgType) {
    throw new MappingError('Dangerous goods preset was created without all required fields');
  }

  return {
    data: {
      siteId,
      // notifiable: isNotifiable(preset),
      properShippingName: preset.properShippingName,
      technicalName: preset.technicalName,
      productDescription: preset.productDescription,
      unNumber: preset.unNumber,
      classOrDivision: isDeclarable(preset) ? preset.classOrDivision : undefined,
      subsidiaryRisks: isDeclarable(preset) ? preset.subsidiaryRisks : undefined,
      packingGroup: isDeclarable(preset) ? preset.packingGroup : undefined,
      receptacle: isDeclarable(preset) ? preset.receptacle : undefined,
      receptacleAggregateQuantity:
        preset.receptacleAggregateQuantityAmount && preset.receptacleAggregateQuantityUnit
          ? {
              amount: Number(preset.receptacleAggregateQuantityAmount),
              unit: preset.receptacleAggregateQuantityUnit,
            }
          : undefined,
      // notification: isNotifiable(preset) ? preset.notification : undefined,
    },
  };
};

type UpdatePresetRequest = operations['updateDangerousGoodsPreset']['requestBody']['content']['application/json'];
export const mapDGFormDataToUpdatePresetRequest = (preset: DGFormData, siteId: string): UpdatePresetRequest => {
  if (!siteId || !preset.properShippingName || !preset.unNumber || !preset.dgType) {
    throw new MappingError('Dangerous goods preset was created without all required fields');
  }

  return {
    data: {
      siteId,
      // notifiable: isNotifiable(preset),
      properShippingName: preset.properShippingName,
      technicalName: preset.technicalName,
      productDescription: preset.productDescription,
      unNumber: preset.unNumber,
      classOrDivision: isDeclarable(preset) ? preset.classOrDivision : undefined,
      subsidiaryRisks: isDeclarable(preset) ? preset.subsidiaryRisks : undefined,
      packingGroup: isDeclarable(preset) ? preset.packingGroup : undefined,
      receptacle: isDeclarable(preset) ? preset.receptacle : undefined,
      receptacleAggregateQuantity:
        preset.receptacleAggregateQuantityAmount && preset.receptacleAggregateQuantityUnit
          ? {
              amount: Number(preset.receptacleAggregateQuantityAmount),
              unit: preset.receptacleAggregateQuantityUnit,
            }
          : undefined,
      // notification: isNotifiable(preset) ? preset.notification : undefined,
    },
  };
};

type SearchPresetResponse =
  operations['searchDangerousGoodsPresets']['responses']['200']['content']['application/json'];
type SearchPresetResponseItem = SearchPresetResponse['data'][number];
type DangerousGoodsPresetWithSummaryHighlight = DangerousGoodsPresetSummary;

const mapDangerousGoodsPresetSummaryWithHighlight = (
  response: SearchPresetResponseItem,
): DangerousGoodsPresetWithSummaryHighlight =>
  dangerousGoodsPresetSummaryFactory.create({
    id: response.id,
    siteId: response.siteId,
    notifiable: false,
    properShippingName: response.properShippingName,
    technicalName: response.technicalName,
    productDescription: response.productDescription,
    unNumber: response.unNumber,
    classOrDivision: response.classOrDivision,
    subsidiaryRisks: response.subsidiaryRisks,
    packingGroup: response.packingGroup as DangerousGoodsPackingGroup | undefined,
    receptacle: response.receptacle,
    receptacleAggregateQuantity: response.receptacleAggregateQuantity
      ? {
          amount: response.receptacleAggregateQuantity?.amount,
          unit: response.receptacleAggregateQuantity?.unit as DangerousGoodsQuantityUnit,
        }
      : undefined,
    lastUsed: response.meta?.lastUsed ? Temporal.Instant.from(response.meta.lastUsed) : undefined,
  });

export const mapSearchDangerousGoodsPresetResponseToDangerousGoodsPreset = (response: SearchPresetResponse) =>
  mapPaginatedResponse(mapDangerousGoodsPresetSummaryWithHighlight, response);
