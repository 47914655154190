const addressBookInstrumentationTypes = {
  ENTRY_SELECTED: 'address book entry selected',
  ENTRY_CREATED: 'address book entry created',
  ENTRY_UPDATED: 'address book entry updated',
  ENTRY_DELETED: 'address book entry deleted',

  SUGGESTION_DELETED: 'address book suggestion deleted',

  BULK_ENTRY_IMPORTED: 'address book bulk entry imported',
  BULK_ENTRY_DELETED: 'address book entry bulk deleted',

  CONTACT_SELECTED: 'address book contact selected',
  CONTACT_CREATED: 'address book contact created',
  CONTACT_UPDATED: 'address book contact updated',
  CONTACT_DELETED: 'address book contact deleted',

  ENTRY_OPENED: 'address book entry opened',

  USER_LOCATION_SELECTED: 'address book duplicate suggestion dismissed',
  DUPLICATE_SUGGESTION_SELECTED: 'address book duplicate suggestion selected',

  VALIDATION_ERROR: 'address book validation error',
};

export default addressBookInstrumentationTypes;
