import { asyncImport, withContextPrefix } from '@/shared/router/helpers';

const SitePreferencesRoutes = [
  ...withContextPrefix(
    {
      path: 'site-preferences',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: '',
        name: 'site-preferences.manage',
        component: asyncImport(() => import('@/app/modules/SitePreferences/views/ManageSitePreferences.vue')),
        meta: {
          title: 'Carrier service preferences',
          enableFullStory: false,
          context: {
            allowed: ['site'],
          },
        },
      },
    ],
  ),
];

export default SitePreferencesRoutes;
