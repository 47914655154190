import type { App, Component, InjectionKey } from 'vue';

import { h } from 'vue';
import { Router, RouterLink, useRouter } from 'vue-router';

import { AppError, UnhandleableError, PermissionDeniedError as PermissionDeniedAppError } from './errors';
import { errorIsCausedBy, isUnhandleableError, errorIsCausedByAnyOf } from './helpers';

import { PollyTimeoutError } from '@/shared/polly';
import {
  ApiClientError,
  NotAuthenticatedError,
  NotFoundError,
  PreconditionFailedClientError,
  PermissionDeniedError,
} from '@/shared/services/api-client';
import { AuthFeedbackError } from '@/shared/services/auth/errors';

import ResourceError from '@/shared/errorHandling/errors/ResourceError';
import NetworkError from '@/shared/services/api-client/errors/NetworkError';

export const reloadCallback = (router?: Router) => () => (router ? router.go(0) : document.location.reload());

export type ErrorDisplayHandler = {
  check: (error: Error) => boolean;
  component: (error: Error) => Component;
};

export type ErrorHandlingConfig = {
  errorDisplayHandlers: ErrorDisplayHandler[];
  fallbackComponent: Component;
};

export const contextInjectionKeyConfig = Symbol('errorHandling:config') as InjectionKey<ErrorHandlingConfig>;
export const contextInjectionKeyDisplayHandlers = Symbol('errorHandling:displayHandlers') as InjectionKey<
  ErrorDisplayHandler[]
>;

const unhandleableErrorDisplayHandler: ErrorDisplayHandler = {
  check: error => error instanceof UnhandleableError || isUnhandleableError(error),
  component: error => ({
    setup() {
      const router = useRouter();
      return () =>
        h('p', [
          isUnhandleableError(error) || !error.message ? 'Something went wrong. ' : `${error.message}. `,
          h('a', { onClick: () => (router ? router.go(0) : document.location.reload()) }, 'Try refreshing the page'),
        ]);
    },
  }),
};
const defaultErrorDisplayHandler: ErrorDisplayHandler = {
  check: () => true, // left at the end, this will match all unmatched errors
  component: error => ({
    setup() {
      logger.warn('errorHandling could not find a display handler', { error });
      return () => h('p', 'Something went wrong with the app. Our engineering team has been notified.');
    },
  }),
};

const defaultFallbackComponent: Component = {
  setup() {
    return () => h('div', 'Something went wrong');
  },
};

export default function configureErrorHandling(app: App, appConfig: Partial<ErrorHandlingConfig> = {}) {
  const { errorDisplayHandlers = [], fallbackComponent = defaultFallbackComponent } = appConfig;

  const config: ErrorHandlingConfig = {
    errorDisplayHandlers: [
      ...errorDisplayHandlers,
      unhandleableErrorDisplayHandler, // default handling for syntax errors, etc
      defaultErrorDisplayHandler, // catch all if we can't find a better handler
    ],
    fallbackComponent,
  };

  app.provide(contextInjectionKeyConfig, config);
}

// error handlers to be used across all applications, can be extended by app-specific handlers via `configureErrorHandling function`
export const sharedDisplayHandlers: ErrorDisplayHandler[] = [
  // first match will be displayed
  {
    check: error => errorIsCausedBy(error, NotAuthenticatedError),
    component: () => [
      'You need to log in to access this resource. ',
      h(RouterLink, { to: { name: 'auth.login' } }, () => 'Log in'),
      '.',
    ],
  },
  {
    check: error => errorIsCausedByAnyOf(error, [PermissionDeniedAppError, PermissionDeniedError]),
    component: () => h('p', 'You do not have permission to access to the requested resource.'),
  },
  {
    check: error => errorIsCausedBy(error, NetworkError),
    component: () => h('p', 'Unable to connect. Check your internet connection and try again.'),
  },
  {
    check: error => error instanceof ResourceError,
    component: error => ({
      setup() {
        const router = useRouter();
        return () =>
          h('p', [
            error.message,
            ' Re-enter the missing details or ',
            h('a', { onClick: reloadCallback(router) }, 'try refreshing the page'),
            '.',
          ]);
      },
    }),
  },
  {
    check: error => error instanceof NotFoundError,
    component: () => ({
      setup() {
        const router = useRouter();
        return () =>
          h('p', [
            'The requested resource was not found. ',
            h('a', { onClick: () => (router ? router.go(-1) : window.history.go(-1)) }, 'Go back'),
          ]);
      },
    }),
  },
  {
    check: error => error instanceof PreconditionFailedClientError,
    component: () => ({
      setup() {
        const router = useRouter();
        return () =>
          h('p', [
            'There was a problem with the request. Please ',
            h('a', { onClick: reloadCallback(router) }, 'reload'),
            ' or try again.',
          ]);
      },
    }),
  },
  {
    check: error => error instanceof PollyTimeoutError,
    component: () => ({
      setup() {
        const router = useRouter();
        return () =>
          h('p', [
            'This task is taking longer than expected. ',
            h('a', { onClick: reloadCallback(router) }, 'Try refreshing the page'),
          ]);
      },
    }),
  },
  {
    check: error => error instanceof ApiClientError,
    component: () => ({
      setup() {
        const router = useRouter();
        return () =>
          h('p', ['There was an error. ', h('a', { onClick: reloadCallback(router) }, 'Try refreshing the page')]);
      },
    }),
  },
  {
    check: error => error instanceof AuthFeedbackError && !!error.message,
    component: error => ({
      setup() {
        // AuthFeedbackError is used to displayed messages to the user
        return () => h('p', error.message);
      },
    }),
  },
  {
    check: error => error instanceof AppError,
    component: error => ({
      setup() {
        // AppError instances should have user-friendly messages
        return () => h('p', error.message || 'Something went wrong.');
      },
    }),
  },
];
