import { shake } from 'radash';

import { formatAgreedService } from '@/shared/formatters';

import Quote from './Quote';
import QuoteWithService from './QuoteWithService';

export function isSelectable(quote: Quote): boolean {
  return !!quote.selectable;
}

export function quoteSortDisplay(quote1: QuoteWithService, quote2: QuoteWithService): number {
  if (!quote1.selectable && !quote2.selectable) {
    return formatAgreedService(quote1.agreedServiceModel).localeCompare(formatAgreedService(quote2.agreedServiceModel));
  }

  if (quote1.selectable && !quote2.selectable) return -1;
  if (!quote1.selectable && quote2.selectable) return 1;

  const quote1HasEvaluation = Object.keys(quote1.evaluation).length > 0;
  const quote2HasEvaluation = Object.keys(quote2.evaluation).length > 0;

  if (quote1HasEvaluation && quote2HasEvaluation) {
    return formatAgreedService(quote1.agreedServiceModel).localeCompare(formatAgreedService(quote2.agreedServiceModel));
  }
  if (quote1HasEvaluation && !quote2HasEvaluation) return 1;
  if (!quote1HasEvaluation && quote2HasEvaluation) return -1;

  const priceRankDifference = (quote1.price.rank ?? 9999) - (quote2.price.rank ?? 9999);
  if (priceRankDifference !== 0) return priceRankDifference;

  return formatAgreedService(quote1.agreedServiceModel).localeCompare(formatAgreedService(quote2.agreedServiceModel));
}

export function removeEmptyEvaluations<T>(evaluations: T extends object ? T : never) {
  return shake(evaluations, e => (Array.isArray(e) ? !e.length : !e));
}
