<template>
  <HeaderButton v-if="options.length === 1" class="context-button static" v-bind="$attrs">
    <div class="context-button-content">
      <div class="context-button-label">{{ title }}</div>
      <div v-sensitive class="context-button-value">{{ selectedName }}</div>
    </div>
    <div class="context-button-icon-container">
      <SvgIcon :svg="ChevronDownIcon" size="small" class="context-button-icon" />
    </div>
  </HeaderButton>
  <ComboBox v-else scrollable v-bind="$attrs" @open="filterRef.focus()" @close="filter = ''">
    <template #trigger>
      <HeaderButton class="context-button">
        <div class="context-button-content">
          <div class="context-button-label">{{ title }}</div>
          <div v-sensitive class="context-button-value">{{ selectedName }}</div>
        </div>
        <div class="context-button-icon-container">
          <SvgIcon :svg="ChevronDownIcon" size="small" class="context-button-icon" />
        </div>
      </HeaderButton>
    </template>
    <template #menu="{ resetHighlight }">
      <ListBoxSearch ref="filterRef" v-model="filter" sensitive @update:model-value="resetHighlight" />
      <ListBoxItemRouterLink
        v-for="option in filteredOptions"
        :key="option.id"
        :to="createLink(option)"
        sensitive
        class="context-item"
      >
        <ControlRadio v-model="selectedRadioOption" :value="option.id" class="context-radio" />
        <slot name="item" v-bind="{ option, filter }">
          <TextHighlight :text="option.name" :highlight="filter" />
        </slot>
      </ListBoxItemRouterLink>
    </template>
  </ComboBox>
</template>

<script setup>
  import { computed, ref } from 'vue';

  import ComboBox from '@/shared/components/ComboBox.vue';
  import ControlRadio from '@/shared/components/controls/ControlRadio.vue';
  import { ListBoxItemRouterLink, ListBoxSearch } from '@/shared/components/list-box';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import ChevronDownIcon from '@/shared/icons/svgs/chevron-arrow-down.svg';
  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';
  import TextHighlight from '@/shared/TextHighlight/TextHighlight.vue';

  import HeaderButton from '@/app/layout/components/HeaderButton.vue';

  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    createLink: {
      type: Function,
      required: true,
    },
    selected: {
      type: String,
      default: '',
    },
    overview: {
      type: String,
      default: null,
    },
  });

  const selectedName = computed(() => {
    if (!props.selected) return props.overview || ' ';
    return props.options.find(o => o.id === props.selected)?.name;
  });

  const filter = ref('');
  const filterRef = ref();
  const filteredOptions = computed(() => {
    const options = filter.value
      ? props.options.filter(o => o.name.toLowerCase().includes(filter.value.toLowerCase()))
      : [...props.options];
    if (props.overview) {
      options.unshift({
        id: '',
        name: 'Sites overview',
      });
    }
    return options;
  });

  const selectedRadioOption = computed(() => props.selected);

  const vSensitive = createSensitiveDirective(true);
</script>

<style scoped lang="scss">
  .context-button {
    justify-content: space-between;
    height: 48px;

    &.static {
      pointer-events: none;
      .context-button-icon-container {
        display: none;
      }
    }
  }

  .context-button-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .context-button-icon-container {
    width: 12px;
    margin-left: 20px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  :deep(label.radio) {
    font-size: 12px; // radio scales on font size
  }

  .context-button-icon {
    color: var(--copy-70);
  }

  .context-button-label {
    font-size: 11px;
    color: var(--copy-70);
    line-height: 1.2;
  }

  .context-button-value {
    @include Subhead2-default;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
  }

  :deep(.list-box-item-router-link) {
    display: flex;
    align-items: center;
  }
</style>
