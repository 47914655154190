import { ConsignmentConsolidationSuggestion as ConsolidationSuggestion } from '@/shared/models';
import APIClient from '@/shared/services/api-client';

import { operations } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';

import * as mappers from './mappers';
import ConsignmentFormData from '@/shared/models/Consignment/ConsignmentFormData';
import ConsignmentFormSupplementalData from '@/shared/models/Consignment/ConsignmentFormSupplementalData';

export default class ConsolidationClient {
  private apiClient: APIClient;

  constructor(apiClient: APIClient) {
    this.apiClient = apiClient;
  }

  public async requestSuggestions(
    consignment: ConsignmentFormData,
    supplementalData: ConsignmentFormSupplementalData,
  ): Promise<ConsolidationSuggestion[]> {
    if (!supplementalData.siteId) throw new Error('siteId is required');

    const response = await this.apiClient.post<
      operations['getConsolidationCandidates']['responses']['200']['content']['application/json']
    >(
      '/v0/consolidations',
      mappers.consolidation.consignmentToConsolidationRequest(consignment, {
        siteId: supplementalData.siteId,
      }),
    );

    return mappers.consolidation.clientConsolidationCandidatesToConsolidationSuggestions(response.data);
  }
}
