// configure geppetto shared config so that logger and error monitoring are in place
// importing with ?chunkName=shared-config ensures this will not be inlined, see vite.config.ts
import '@/sharedConfig?chunkName=shared-config';

import Veritas from 'veritas';
import { createApp } from 'vue';
import timeago from 'vue-timeago3';

import Toast from 'vue-toastification';

import ErrorHandling from '@/shared/errorHandling';
import ErrorMonitoring from '@/shared/ErrorMonitoring';
import FullStory from '@/shared/FullStory';
import pageTitle from '@/shared/pageTitle';
import pluralizeMixin from '@/shared/pluralize/pluralizeMixin';
import RouterOpen from '@/shared/RouterOpen';
import routerProgress from '@/shared/routerProgress';
import sensitivePlugin from '@/shared/sensitive';
import testingHelper from '@/shared/utils/testingHelper';
import vuexAsyncLoading from '@/shared/vuex-async-loader';
import { VersionMonitor } from '@/shared/plugins/VersionMonitor';

import App from '@/app/App.vue';
import { appErrorHandlingConfig } from '@/app/errorHandling/errorHandlingConfig';
import instrumentation from '@/app/instrumentation';
import componentIdPlugin from '@/app/plugins/component-id';
import router from '@/app/router';
import { plugin as forceNavigationPlugin } from '@/shared/router/forceNavigation';
import onWindowBlocked from '@/shared/router/WindowBlocked';
import store from '@/app/store';

declare global {
  interface Window {
    testingHelper: TestingHelper;
  }
}

// add some helper methods to make testing/synthetics easier
window.testingHelper = testingHelper;

const ProtectedApp = ErrorHandling.createProtectedApp(App);

// main vue setup
const app = createApp(ProtectedApp).use(store).use(router);

// register extra plugins
app
  .use(ErrorMonitoring)
  .use(instrumentation as never, { store, router })
  .use(VersionMonitor, {
    initialSHA: import.meta.env.VITE_GITSHA,
    router,
  })
  .use(Veritas)
  .use(componentIdPlugin)
  .use(vuexAsyncLoading, { store })
  .use(ErrorHandling, appErrorHandlingConfig)
  .use(sensitivePlugin)
  .use(FullStory, { router })
  .use(pageTitle)
  .use(RouterOpen, { router, onOpenBlocked: onWindowBlocked })
  .use(routerProgress, { router })
  .use(forceNavigationPlugin, { router })
  .use(timeago)
  .use(Toast, {
    transition: 'toast-slide',
  });

app.mixin(pluralizeMixin);

app.mount('#app');
