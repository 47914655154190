import FullStory from '@/shared/FullStory';
import VueInstrumentation from '@/shared/instrumentation/VueInstrumentation';

import userMonitor from '@/app/instrumentation/userMonitor';

export enum AutoprintConfigInstrumentation {
  InstallationRefreshClicked = 'installation refresh clicked',
  HelpGuideDownloaded = 'autoprint help guide downloaded',
  InstallerDownloaded = 'autoprint installer downloaded',
  InstallationCompleted = 'autoprint installation completed',
  ConnoteOpenDocumentPreferenceUpdated = 'connote open document preference updated',
}

export function registerHandlers(instrumentation: VueInstrumentation) {
  instrumentation.on(AutoprintConfigInstrumentation.InstallationRefreshClicked, () => {
    userMonitor.event('autoprint refresh clicked');
    FullStory.event('autoprint refresh clicked', {});
  });

  instrumentation.on(AutoprintConfigInstrumentation.HelpGuideDownloaded, () => {
    userMonitor.event('autoprint help guide downloaded');
    FullStory.event('autoprint help guide downloaded', {});
  });

  instrumentation.on(AutoprintConfigInstrumentation.InstallerDownloaded, () => {
    userMonitor.event('autoprint installer downloaded');
    FullStory.event('autoprint installer downloaded', {});
  });

  instrumentation.on(
    AutoprintConfigInstrumentation.InstallationCompleted,
    ({ clientDetected }: { clientDetected: boolean }) => {
      const context = {
        autoprint: {
          // @context.autoprint.clientDetected (facet)
          clientDetected,
        },
      };

      userMonitor.event('autoprint installation completed', context);
      FullStory.event('autoprint installation completed', {
        clientDetected,
      });
    },
  );

  instrumentation.on(
    AutoprintConfigInstrumentation.ConnoteOpenDocumentPreferenceUpdated,
    ({ preference }: { preference: boolean }) => {
      userMonitor.event('connote open document preference updated', {
        connoteOpenDocument: preference,
      });
      FullStory.event('connote open document preference updated', {});
    },
  );
}
