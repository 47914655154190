// TODO delete this file
import invert from 'lodash/invert';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';

import config from '@/config';
import { isValidISODateRange } from '@/shared/DateTime/helpers';
import { temporalPlainDateFromDate } from '@/shared/DateTime/mappers';
import { ConsignmentFilterTypes } from '@/shared/models/Consignment/ConsignmentResultSet';
import { consignmentService } from '@/app/common/services';

export const filterMapping = {
  dispatchDate: 'dispatchDate',
  receiver: 'receiver.name',
  service: 'agreedService.id',
  type: 'type',
  deliveryTimeSlot: 'deliveryTimeSlot',
  sites: 'site.id',
  transferType: 'pallets.transferType',
};
const reverseFilterMapping = invert(filterMapping);

export const types = {
  actions: {
    setPage: 'setPage',
    setLimit: 'setLimit',
    setSearch: 'setSearch',

    setImplicitFilters: 'setImplicitFilters',
    setDefaultFilters: 'setDefaultFilters',
    setDefaultSort: 'setDefaultSort',

    setSort: 'setSort',

    reset: 'reset',
    clear: 'clear',

    getRequestSerial: 'getRequestSerial',

    listConsignments: 'listConsignments',
    updateFromQuery: 'updateFromQuery',
  },

  getters: {
    results: 'results',

    page: 'page',
    limit: 'limit',
    numPages: 'numPages',
    total: 'total',

    search: 'search',
    sort: 'sort',
    sortString: 'sortString',

    // filters
    filters: 'filters',
    mappedFilters: 'mappedFilters',
    visibleFilters: 'visibleFilters',
    appliedFilters: 'appliedFilters',
    consignmentType: 'consignmentType',
    filterDeliveryTimeSlot: 'filterDeliveryTimeSlot',

    agreedService: 'agreedService',
    dispatchDate: 'dispatchDate',
    transferType: 'transferType',
    receiver: 'receiver',
    sites: 'sites',
    exportURL: 'exportURL',
  },
  mutations: {
    SET_RESULTS: 'SET_RESULTS',
    SET_RESULTS_SERIAL: 'SET_RESULTS_SERIAL',
    INCREMENT_REQUEST_SERIAL: 'INCREMENT_REQUEST_SERIAL',

    SET_PAGE: 'SET_PAGE',
    SET_LIMIT: 'SET_LIMIT',

    SET_DEFAULT_SORT: 'SET_DEFAULT_SORT',
    SET_SORT: 'SET_SORT',

    SET_FILTER: 'SET_FILTER',
    CLEAR_FILTER: 'CLEAR_FILTER',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    SET_SEARCH: 'SET_SEARCH',
  },
};

export const createDefaultState = () => ({
  results: [],
  resultsSerial: 0,
  requestSerial: 0,

  page: 1,
  limit: config.resourcesPerPage,
  numPages: 0,
  total: 0,

  // filters
  implicitFilters: {},
  defaultFilters: {},
  filters: {},

  // sorts
  implicitSort: ['-updated'],
  defaultSort: ['-dispatchDate'],
  sort: [],

  // search
  search: '',
});

export const actions = {
  [types.actions.setPage]: ({ commit }, page) => commit(types.mutations.SET_PAGE, page),
  [types.actions.setLimit]: ({ commit }, limit) => commit(types.mutations.SET_LIMIT, limit),

  [types.actions.setImplicitFilters]: ({ commit }, filters) => {
    commit(types.mutations.CLEAR_FILTERS, 'implicitFilters');
    Object.keys(filters).forEach(filter =>
      commit(types.mutations.SET_FILTER, { type: 'implicitFilters', key: filter, value: filters[filter] }),
    );
  },
  [types.actions.setDefaultFilters]: ({ commit }, filters) => {
    commit(types.mutations.CLEAR_FILTERS, 'defaultFilters');
    Object.keys(filters).forEach(filter =>
      commit(types.mutations.SET_FILTER, { type: 'defaultFilters', key: filter, value: filters[filter] }),
    );
  },
  [types.actions.setDefaultSort]: ({ commit }, sort) => {
    commit(types.mutations.SET_DEFAULT_SORT, sort);
  },

  [types.actions.setSort]({ commit }, sort) {
    commit(types.mutations.SET_SORT, sort);
  },

  [types.actions.setSearch]({ commit }, search) {
    commit(types.mutations.SET_SEARCH, search);
  },

  [types.actions.getRequestSerial]: ({ commit, state }) => {
    commit(types.mutations.INCREMENT_REQUEST_SERIAL);
    return state.requestSerial;
  },

  [types.actions.reset]: async ({ commit }) => {
    const defaultState = createDefaultState();

    commit(types.mutations.SET_RESULTS, {
      results: defaultState.results,
      numPages: defaultState.numPages,
      total: defaultState.total,
    });
    commit(types.mutations.SET_PAGE, defaultState.page);
    commit(types.mutations.SET_LIMIT, defaultState.limit);
    commit(types.mutations.SET_SORT, defaultState.sort);
    commit(types.mutations.SET_SEARCH, defaultState.search);
    commit(types.mutations.SET_DEFAULT_SORT, defaultState.defaultSort);
    commit(types.mutations.CLEAR_FILTERS, 'filters');
    commit(types.mutations.CLEAR_FILTERS, 'implicitFilters');
    commit(types.mutations.CLEAR_FILTERS, 'defaultFilters');
  },

  [types.actions.clear]: async ({ commit }) => {
    const { results, numPages, total } = createDefaultState();
    commit(types.mutations.SET_RESULTS, { results, numPages, total });
  },

  [types.actions.updateFromQuery]: async ({ commit, dispatch, getters }, { sort, page, ...filters }) => {
    dispatch(types.actions.setSort, sort);
    dispatch(types.actions.setPage, page);

    Object.keys(filterMapping).forEach(filterName => {
      commit(types.mutations.SET_FILTER, { key: filterMapping[filterName], value: filters[filterName] });
    });

    // If we remove all filters in the store from the list the user supplied, we're left with a
    // list of filters that did NOT get applied
    const filtersNotApplied = Object.keys(omit(filters, Object.keys(getters[types.getters.mappedFilters])));

    // Don't fetch results if there were unapplied filters, as the view should remove these and try again
    if (filtersNotApplied.length === 0) {
      await dispatch(types.actions.listConsignments);
    }
  },

  [types.actions.listConsignments]: async ({ getters, commit, dispatch, state }) => {
    // Record a sequence number for this API request
    const serial = await dispatch(types.actions.getRequestSerial);

    const results = await consignmentService.listConsignments({
      limit: getters[types.getters.limit],
      offset: Number((getters[types.getters.page] - 1) * getters[types.getters.limit]),
      sort: getters[types.getters.sortString],
      filters: getters[types.getters.appliedFilters],
      search: state.search,
    });

    // If requests came back out of order, discard results from requests older than what is currently displayed
    if (serial > state.resultsSerial) {
      commit(types.mutations.SET_RESULTS, { results: results.consignments, ...omit(results, 'consignments') });
      commit(types.mutations.SET_RESULTS_SERIAL, serial);
    }
  },
};

export const getters = {
  [types.getters.results]: state => state.results,
  [types.getters.page]: state => state.page,
  [types.getters.limit]: state => state.limit,
  [types.getters.numPages]: state => state.numPages,
  [types.getters.total]: state => state.total,

  [types.getters.search]: state => state.search,
  [types.getters.sort]: state => state.sort || state.defaultSort,
  [types.getters.sortString]: (state, _getters) => {
    // Selected sort keys without direction (remove -)
    const sortKeys = _getters[types.getters.sort].map(sort => sort.replace(/-/gi, ''));
    // Remove any implicit sorts that also exist in user sorts
    const appliedSorts = state.implicitSort.filter(implicit => !sortKeys.includes(implicit.replace(/-/gi, '')));

    return [..._getters[types.getters.sort], ...appliedSorts].join(',');
  },

  // Remove filters with null or undefined values
  [types.getters.filters]: state =>
    pickBy(state.filters, (val, key) => {
      if (key === 'site.id' || key === 'agreedService.id') return typeof val !== 'undefined';
      return !!val;
    }) || {},
  [types.getters.mappedFilters]: (state, _getters) =>
    mapKeys(_getters[types.getters.filters], (val, key) => reverseFilterMapping[key]),
  [types.getters.visibleFilters]: (state, _getters) => ({
    ...pickBy(state.defaultFilters, val => val),
    ..._getters[types.getters.filters],
  }),
  [types.getters.appliedFilters]: (state, _getters) => ({
    ..._getters[types.getters.visibleFilters],
    ...state.implicitFilters,
  }),
  [types.getters.consignmentType]: (state, _getters) =>
    _getters[types.getters.visibleFilters].type || ConsignmentFilterTypes.All,
  [types.getters.filterDeliveryTimeSlot]: (state, _getters) =>
    _getters[types.getters.visibleFilters].deliveryTimeSlot || false,

  [types.getters.agreedService]: (state, _getters) => _getters[types.getters.visibleFilters]['agreedService.id'],
  [types.getters.dispatchDate]: (state, _getters) => _getters[types.getters.visibleFilters].dispatchDate,
  [types.getters.transferType]: (state, _getters) =>
    _getters[types.getters.visibleFilters]['pallets.transferType'] || 'all',
  [types.getters.receiver]: (state, _getters) => _getters[types.getters.visibleFilters]['receiver.name'],
  [types.getters.sites]: (state, _getters) => _getters[types.getters.visibleFilters]['site.id'],

  [types.getters.exportURL]: (state, _getters) =>
    consignmentService.listConsignmentsUrlBuilder({
      sort: _getters[types.getters.sortString],
      filters: _getters[types.getters.appliedFilters],
      exportFormat: 'csv',
    }),
};

export const mutations = {
  [types.mutations.SET_RESULTS](state, { results, numPages, total }) {
    state.results = [...results];
    state.numPages = numPages;
    state.total = total;
  },
  [types.mutations.SET_RESULTS_SERIAL](state, serial) {
    state.resultsSerial = serial;
  },
  [types.mutations.INCREMENT_REQUEST_SERIAL](state) {
    state.requestSerial += 1;
  },

  [types.mutations.SET_PAGE](state, page) {
    state.page = Number(page) ? Number(page) : 1;
  },
  [types.mutations.SET_LIMIT](state, limit) {
    state.limit = limit;
  },

  [types.mutations.SET_DEFAULT_SORT](state, sort) {
    state.defaultSort = sort;
  },
  [types.mutations.SET_SORT](state, sort) {
    state.sort = typeof sort === 'string' ? sort.split(',') : sort;
  },
  [types.mutations.SET_SEARCH](state, search) {
    state.search = search;
  },

  [types.mutations.SET_FILTER](state, { type = 'filters', key, value }) {
    if (!key || !['filters', 'defaultFilters', 'implicitFilters'].includes(type)) {
      throw new Error(`Cannot set filter ${key} as filter type ${type}`);
    }

    // Helpers for setting/removing this specific filter
    const set = newValue => {
      state[type][key] = newValue;
    };
    const removeFilter = () => delete state[type][key];

    // If users provides an empty filter, or one that matches the default value, unset it
    if (typeof value === 'undefined' || value === null || (type === 'filters' && value === state.defaultFilters[key])) {
      removeFilter();
      return;
    }

    // Apply special rules and formatting before committing filters to state
    switch (key) {
      case 'type':
        if (value === ConsignmentFilterTypes.All) {
          removeFilter();
        } else if (Object.values(ConsignmentFilterTypes).includes(value)) {
          set(value);
        } else {
          removeFilter();
        }
        break;

      case 'agreedService.id':
        if (value !== 'all') {
          set(value);
        } else {
          removeFilter();
        }
        break;

      case 'dispatchDate':
        if (value instanceof Date) {
          set(temporalPlainDateFromDate(value).toString());
        } else if (isValidISODateRange(value)) {
          set(value);
        } else {
          removeFilter();
          // throw new Error('Invalid dates specified');
        }
        break;
      case 'pallets.transferType':
        if (['carrier', 'receiver'].includes(value)) {
          set(value);
        } else {
          removeFilter();
        }
        break;
      case 'site.id':
        if (Array.isArray(value)) {
          set(value.join(','));
        } else if (value === 'all') {
          removeFilter();
        } else {
          set(value);
        }
        break;

      default:
        set(value);
        break;
    }
  },
  [types.mutations.CLEAR_FILTERS](state, type = 'filters') {
    state.filters = createDefaultState()[type];
  },
  [types.mutations.CLEAR_FILTER](state, filter) {
    delete state.filters[filter];
  },
};

export default {
  namespaced: true,
  state: createDefaultState,
  actions,
  getters,
  mutations,
};
