<template>
  <ListBoxItem v-bind="itemProps" ref="item" interactive no-padding @click="activateInner">
    <button ref="inner" type="button" class="list-box-item-button" :tabindex="disableTab ? -1 : 0" @click="click">
      <slot></slot>
    </button>
  </ListBoxItem>
</template>

<script setup>
  import { computed, inject, ref } from 'vue';

  import ListBoxItem from './ListBoxItem.vue';

  import LIST_BOX_ITEM_PROPS from './listBoxItemProps';

  const props = defineProps({
    ...LIST_BOX_ITEM_PROPS,
    click: {
      type: Function,
      default: () => {},
    },
  });

  const disableTab = inject('disableTab', false);

  const item = ref(null);
  const inner = ref(null);

  const activateInner = event => {
    if (event.target === item.value?.$el) {
      event.stopImmediatePropagation();
      inner.value?.click();
    }
  };

  const itemProps = computed(() => {
    const { click, ...otherProps } = props;
    return otherProps;
  });
</script>

<style scoped lang="scss">
  .list-box-item-button {
    all: unset;
    @include Paragraph-default;
    padding: var(--space-xxs) 0;
  }
</style>
