import { asyncImport, withContextPrefix } from '@/shared/router/helpers';

const estimateRoutes = [
  ...withContextPrefix(
    {
      path: 'estimate',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: 'create/:estimateType',
        name: 'estimate.create.type',
        component: asyncImport(() => import('@/app/modules/Estimate/views/EstimateCreate.vue')),
        props: true, // pass estimateType param as a prop
        meta: {
          title: 'Create estimate',
          enableFullStory: true,
          context: {
            allowed: ['site'],
            selectSiteFor: 'estimate',
          },
        },
      },
      {
        name: 'estimate.create',
        path: 'create',
        redirect: to => ({ name: 'estimate.create.type', params: { estimateType: 'outbound', ...to.params } }),
      },
      {
        path: 'manage',
        name: 'estimate.manage',
        component: asyncImport(() => import('@/app/modules/Estimate/views/EstimateManage.vue')),
        meta: {
          title: 'Manage estimates',
          enableFullStory: true,
          context: {
            allowed: ['site', 'org'],
          },
        },
      },
      {
        path: ':estimateId',
        name: 'estimate.view',
        component: asyncImport(() => import('@/app/modules/Estimate/views/EstimateView.vue')),
        props: true,
        meta: {
          title: 'View estimate',
          enableFullStory: true,
        },
      },
      {
        path: '',
        redirect: { name: 'estimate.create' },
      },
    ],
  ),
];

export default estimateRoutes;
