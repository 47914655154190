import uniqueId from 'lodash/uniqueId';

import DangerousGoods from '@/shared/models/DangerousGoods/DangerousGoods';
import type { PalletCounts } from '@/shared/types/ConsignmentPallets';
import ConsignmentFormDataLineItem from '@/shared/models/Consignment/ConsignmentFormDataLineItem';

export default interface ConsignmentLineItem {
  id?: UUID;
  /** item id or autogenerated key for vue for loops keys.
   * id is uuid and uniqueId is serial, so they never overlap */
  key?: UUID;
  /** @example Carton */
  packagingType?: string;
  /** @example cfa53003-7352-441d-84c4-8c27ac049f5f */
  shippingItemPresetId?: UUID;
  /** @example Carton - A1 */
  description?: string;
  /** @example 1 */
  quantity: number;
  /** @example 1000 */
  length?: Millimeters;
  /** @example 1000 */
  width?: Millimeters;
  /** @example 1000 */
  height?: Millimeters;
  volume?: MillimetersCubed;
  /** @example 2000 */
  weight?: Grams;
  /** @example ORDER1234 */
  reference?: string;
  itemIds: UUID[];

  pallets?: PalletCounts;
  dangerousGoods?: DangerousGoods[];
}

const create = ({
  id,
  description,
  height,
  length,
  packagingType,
  quantity,
  reference,
  weight,
  width,
  volume,
  itemIds,
  shippingItemPresetId,
  pallets,
  dangerousGoods,
}: Omit<ConsignmentLineItem, 'key'>): ConsignmentLineItem => ({
  id,
  key: id || uniqueId(),
  description,
  packagingType,
  length,
  height,
  width,
  volume,
  weight,
  quantity,
  reference,
  itemIds,
  shippingItemPresetId,
  pallets,
  dangerousGoods,
});

const isNullOrUndefined = (val: unknown) => val === null || val === undefined;
export const isEmptyItem = (lineItem: ConsignmentFormDataLineItem) =>
  isNullOrUndefined(lineItem.reference) &&
  isNullOrUndefined(lineItem.description) &&
  isNullOrUndefined(lineItem.height) &&
  isNullOrUndefined(lineItem.width) &&
  isNullOrUndefined(lineItem.length) &&
  isNullOrUndefined(lineItem.weight);

export const consignmentLineItemFactory = {
  create,
};
