import { RouteLocationNamedRaw } from 'vue-router';

import type { Consignment, ConsignmentSummary, MovementFlowType } from '@/shared/models';

export type MinimalConsignment = Pick<Consignment, 'id' | 'siteId'>;

// TODO: normalise Consignment and ConsignmentSummary models handling of site id
const getSiteIdFromConsignment = (consignment: MinimalConsignment | ConsignmentSummary): UUID => {
  if ('siteId' in consignment) return consignment.siteId!;
  if ('site' in consignment) return consignment.site.id;
  throw new Error('cannot construct consignment link without siteId');
};

export const consignmentsReadyToManifestRoute = (filters: {
  type?: MovementFlowType;
  dispatchDate?: string;
  serviceId?: UUID;
}): RouteLocationNamedRaw => ({
  name: 'consignment.readyToManifest',
  params: {},
  query: {
    ...(filters.type && { type: filters.type }),
    ...(filters.dispatchDate && { dispatchDate: filters.dispatchDate }),
    ...(filters.serviceId && { service: filters.serviceId }),
  },
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment view page.
 */
export const consignmentViewRoute = (
  consignment: MinimalConsignment | ConsignmentSummary,
  manifestDoc = false,
): RouteLocationNamedRaw => ({
  name: 'consignment.view',
  params: {
    consignmentId: consignment.id,
    contextType: 'site',
    contextId: getSiteIdFromConsignment(consignment),
  },
  query: {
    ...(manifestDoc && { manifestDoc: `${manifestDoc}` }),
  },
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment view page.
 */
export const consignmentEditRoute = (consignment: MinimalConsignment | ConsignmentSummary): RouteLocationNamedRaw => ({
  name: 'consignment.edit',
  params: {
    consignmentId: consignment.id,
    contextType: 'site',
    contextId: getSiteIdFromConsignment(consignment),
  },
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment create page.
 */
export const consignmentCreateRoute = (movementFlowType: MovementFlowType): RouteLocationNamedRaw => ({
  name: `consignment.create.${movementFlowType}`,
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the estimate a consignment was created from.
 */
export const consignmentEstimateRoute = (consignment: Consignment): RouteLocationNamedRaw => ({
  name: 'estimate.view',
  params: {
    estimateId: consignment.estimateId,
    contextType: 'site',
    contextId: getSiteIdFromConsignment(consignment), // estimate has same siteId as consignment
  },
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment manifest page with the designated consignment selected.
 */
export const consignmentManifestRoute = (consignment: Consignment | ConsignmentSummary): RouteLocationNamedRaw => ({
  name: 'manifest.create',
  ...('site' in consignment && {
    params: {
      contextType: 'site',
      contextId: getSiteIdFromConsignment(consignment),
    },
  }),
  query: {
    dispatchDate: consignment.dispatchDate,
    consignmentId: consignment.id,
    type: consignment.type,
  },
});

export const resolveIssuesRoute = (query?: {
  dispatchDate?: string;
  serviceId?: string;
  type?: MovementFlowType;
}): RouteLocationNamedRaw => ({
  name: 'consignment.issues',
  query,
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment label page, typically opened in a new page.
 */
export const consignmentLabelDocumentRoute = (
  consignment: Pick<Consignment, 'id'>,
  labelUrl = 'labels.pdf',
): RouteLocationNamedRaw => ({
  name: 'document',
  params: {
    documentType: 'label',
    documentId: consignment.id,
  },
  query: {
    labelUrl: encodeURIComponent(labelUrl),
  },
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment connote page, typically opened in a new page.
 */
export const consignmentConnoteDocumentRoute = (consignment: Pick<Consignment, 'id'>): RouteLocationNamedRaw => ({
  name: 'document',
  params: {
    documentType: 'connote',
    documentId: consignment.id,
  },
});

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment label page, typically opened in a new page.
 */
export const consignmentManifestDocumentRoute = (
  consignment: Consignment | ConsignmentSummary,
): RouteLocationNamedRaw => {
  if (!consignment.manifest?.id) throw new Error('Missing required route param "manifestId"');
  return {
    name: 'document',
    params: {
      documentType: 'manifest',
      documentId: consignment.manifest.id,
    },
  };
};

/**
 * Returns a Location for a <router-link :to> or $router.push that will navigate
 * the user to the consignment DG summary page, typically opened in a new page.
 */
export const consignmentDGSummaryDocumentRoute = (consignment: Pick<Consignment, 'id'>): RouteLocationNamedRaw => ({
  name: 'document',
  params: {
    documentType: 'dg-summary',
    documentId: consignment.id,
  },
});
