import { DangerousGoodsPreset } from '@/shared/models';
import { type DangerousGoodsPresetSummary } from '@/shared/models/DangerousGoods/DangerousGoodsPresetSummary';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';

import {
  mapDGFormDataToCreatePresetRequest,
  mapDGFormDataToUpdatePresetRequest,
  mapGetDangerousGoodsPresetResponseToDangerousGoodsPreset,
  mapSearchDangerousGoodsPresetResponseToDangerousGoodsPreset,
} from '@/shared/services/dangerousGoodsPresets/mappers/dangerousGoodsPresetMappers';
import { operations, paths } from '@/shared/services/schema/geppetto-org/dangerousgoodspresets.schema';

/* eslint-disable no-restricted-imports */
import DGFormData from '@/app/modules/DangerousGoods/models/DGFormData';

import ListingFilter from '../../models/ListingFilters';
import ApiClient, { ApiClientRequestConfig } from '../api-client';
import { ExtractFilterKeys, formatSearchParams } from '../helpers';

const RESOURCES_PER_PAGE = 30 as const;

export interface SearchDangerousGoodsPresetsParams extends Omit<ListingFilter, 'filters'> {
  filters?: ExtractFilterKeys<operations['searchDangerousGoodsPresets']>;
}

export default class DangerousGoodsPresetsClient {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  public async search(
    { limit = RESOURCES_PER_PAGE, offset = 0, sort = '-unNumber', filters, search }: SearchDangerousGoodsPresetsParams,
    config?: ApiClientRequestConfig,
  ): Promise<PaginatedItems<DangerousGoodsPresetSummary>> {
    if (!filters?.siteId) throw new Error('siteId is a required parameter for DG preset search');

    const params = formatSearchParams({
      limit,
      offset,
      sort,
      search,
      filters,
    });

    const response = await this.apiClient.query<
      paths['/dangerous-goods/presets']['get']['responses']['200']['content']['application/json']
    >('/dangerous-goods/presets', { ...config, params });

    logger.debug('[DangerousGoodsPresetsClient] search presets success', {}, { params, response });

    return mapSearchDangerousGoodsPresetResponseToDangerousGoodsPreset(response.data);
  }

  public async create(preset: DGFormData, siteId: string): Promise<{ id: UUID }> {
    const request = mapDGFormDataToCreatePresetRequest(preset, siteId);

    const response = await this.apiClient.post<
      paths['/dangerous-goods/presets']['post']['responses']['201']['content']['application/json']
    >('/dangerous-goods/presets', request);

    logger.debug('[DangerousGoodsService] Preset created', {}, { request, response });

    return { id: response.data.data.id };
  }

  public async update(preset: DGFormData, siteId: string): Promise<{ id: UUID }> {
    const request = mapDGFormDataToUpdatePresetRequest(preset, siteId);

    const response = await this.apiClient.put<
      paths['/dangerous-goods/presets/{id}']['put']['responses']['default']['content']
    >(`/dangerous-goods/presets/${preset.presetId}`, request);

    logger.debug('[DangerousGoodsService] Preset updated', {}, { presetId: preset.presetId, request, response });

    return { id: preset.presetId! };
  }

  public async fetch(id: UUID, config?: ApiClientRequestConfig): Promise<DangerousGoodsPreset> {
    const response = await this.apiClient.get<
      paths['/dangerous-goods/presets/{id}']['get']['responses']['200']['content']['application/json']
    >(`/dangerous-goods/presets/${id}`, config);

    logger.debug('[DangerousGoodsService] Preset fetched', {}, { presetId: id, response });

    return mapGetDangerousGoodsPresetResponseToDangerousGoodsPreset(response.data);
  }

  public async delete(id: UUID): Promise<void> {
    const response = await this.apiClient.delete<undefined>(`/dangerous-goods/presets/${id}`);

    logger.debug('[DangerousGoodsService] Preset deleted', {}, { presetId: id, response });
    // no throw, success!
  }
}
