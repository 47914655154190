import { DateRange } from '@/shared/DateTime';
import FullStory from '@/shared/FullStory';
import VueInstrumentation from '@/shared/instrumentation/VueInstrumentation';

import userMonitor from '@/app/instrumentation/userMonitor';

import { CONSIGNMENT_TRACK_ETA_FILTER_UPDATE } from './types';

export function registerHandlers(instrumentation: VueInstrumentation) {
  instrumentation.on(CONSIGNMENT_TRACK_ETA_FILTER_UPDATE, ({ eta }: { eta: DateRange | 'all' | 'overdue' }) => {
    userMonitor.event('ETA filter updated', {
      track: {
        eta: eta?.toString() || 'all',
      },
    });

    if (eta === 'overdue') {
      FullStory.event('Overdue selected', {});
    }
  });
}
