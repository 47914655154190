import { Temporal } from '@js-temporal/polyfill';

import { CustomFieldViewSchema } from '@/shared/customFields/types';
import { ConsignmentReceiver, ConsignmentSender } from '@/shared/models/Consignment/ConsignmentAddressee';
import type DangerousGoodsDeclaration from '@/shared/models/DangerousGoods/DangerousGoodsDeclaration';
import { MOVEMENT_FLOW_TYPES_RECORD, MovementFlowType } from '@/shared/models/MovementFlow';
import type { ConsignmentPalletDocketNumbers, PalletTransferType } from '@/shared/types/ConsignmentPallets';

import { components } from '../../services/schema/geppetto-sender-app/consignments.schema';

import ConsignmentLineItem, { consignmentLineItemFactory } from './ConsignmentLineItem';
import {
  ConsignmentProofOfDeliveryEvent,
  ConsignmentTrackingEvent,
  consignmentTrackingEventFactory,
  isProofOfDeliveryEvent,
} from './ConsignmentTrackingEvent';
import { ConsignmentPickupRequest } from './ConsignmentPickupRequest';
import { Organisation } from '@/shared/models';
import Quote from '@/shared/models/Quote/Quote';

export type ConsignmentType = MovementFlowType;
export const CONSIGNMENT_TYPES = MOVEMENT_FLOW_TYPES_RECORD;

export interface ConsignmentPalletData {
  transferType: PalletTransferType;
  docketNumbers: ConsignmentPalletDocketNumbers;
}

export interface ConsignmentLabelOptions {
  newItemsOnly?: string; // boolean
  generateInstructions?: string; // boolean
}

interface LabelDocumentDetails {
  params: ConsignmentLabelOptions;
  meta?: {
    isDefault: boolean;
  };
}

export interface ConsignmentLabelDocuments {
  allLabels?: LabelDocumentDetails;
  newLabels?: LabelDocumentDetails;
}

export type ConsignmentLabelLinks = Record<string, components['schemas']['Link'] | null>;

export interface ConsignmentManifest {
  id: UUID;
  addedAt: Temporal.Instant;
}
export interface ConsignmentTransfer {
  transferredBy: components['schemas']['ConsignmentTransferSummary']['transferredBy'];
  transferredAt?: Temporal.Instant;
}

// Simplified agreed service model for consignments.
export interface ConsignmentAgreedService {
  id: UUID;
  name: string;
  carrier: Organisation;
  carrierServiceId: components['schemas']['UUID'];
  carrierServiceProviderType: 'carrier' | 'receiver' | 'sender';
}

export type ConsignmentTransferPolicy = components['schemas']['ConsignmentTransferPolicy'];

export default interface Consignment {
  id: UUID;
  createdAt: string;
  source?: {
    applicationName: string;
  };
  consignmentNo?: string;
  costCenter?: string;
  dispatchDate: PlainDateString;
  // dispatchDate: PlainDate;
  lineItems: ConsignmentLineItem[];
  manifest?: ConsignmentManifest;
  transfer?: ConsignmentTransfer;
  payerAccount?: string;
  quoteId?: UUID;
  agreedService?: ConsignmentAgreedService;
  receiver: ConsignmentReceiver;
  references: string[];
  sender: ConsignmentSender;
  siteId: UUID;
  tracking: ConsignmentTrackingEvent[];
  type: ConsignmentType;
  updatedAt?: RFC3339InstantString;
  // updatedAt?: Instant;
  pallets?: ConsignmentPalletData;
  estimateId?: UUID;
  orderId?: UUID;
  dangerousGoodsDeclaration?: DangerousGoodsDeclaration;
  issues?: Record<string, number>;
  transferPolicy: ConsignmentTransferPolicy;
  linkedOrders: components['schemas']['ViewConsignment']['linkedOrders'];
  customFields?: CustomFieldViewSchema[];
  // All pickup requests associated with this consignment's freight.
  pickupRequests: ConsignmentPickupRequest[];

  quotes: Quote[];

  // ### DERIVED PROPERTIES
  readonly pods: ConsignmentProofOfDeliveryEvent[];
  readonly items: Record<string, ConsignmentLineItem & { ordinal: number }>;
}

function deriveItemsFromLineItems(lineItems: ConsignmentLineItem[]) {
  return (
    lineItems
      // Derive a list of itemIds, in order, based on the order of lineItems and itemIds in each lineItem.
      // This order is preserved by the back-end, so always guarantees the same result.
      .reduce<string[]>((all, lineItem) => (lineItem.itemIds ? [...all, ...lineItem.itemIds] : all), [])
      // Key the resulting object by itemId for easier access and to match other models
      .reduce<Record<string, ConsignmentLineItem & { ordinal: number }>>(
        (all, itemId: UUID, index: number) => ({
          ...all,
          [itemId]: {
            ...(lineItems.find(lineItem => lineItem.itemIds.includes(itemId)) as ConsignmentLineItem),
            // Add a consistent, unique ordinal for each item based on the order of lineItems / itemIds.
            // This can be used to display "Item 1", "Item 2" against scan events.
            ordinal: index + 1,
          },
        }),
        {},
      )
  );
}

function create({
  id,
  createdAt,
  source,
  consignmentNo,
  type,
  references,
  dispatchDate,
  sender,
  receiver,
  costCenter,
  payerAccount,
  lineItems,
  quoteId,
  agreedService,
  siteId,
  manifest,
  transfer,
  tracking,
  updatedAt,
  pallets,
  estimateId,
  orderId,
  dangerousGoodsDeclaration,
  issues,
  transferPolicy,
  linkedOrders,
  customFields,
  pickupRequests,
  quotes,
}: Omit<Consignment, 'pods' | 'items'>): Consignment {
  const lineItemModels = lineItems.map(consignmentLineItemFactory.create);
  const trackingEventModels = tracking.map(consignmentTrackingEventFactory.create);
  const podEventModels = trackingEventModels.filter(isProofOfDeliveryEvent);

  return {
    id,
    createdAt,
    source,
    consignmentNo,
    type,
    references,
    dispatchDate,
    sender,
    receiver,
    costCenter,
    payerAccount,
    quoteId,
    agreedService,
    siteId,
    manifest,
    transfer,
    updatedAt,
    customFields,
    quotes,

    // mapped fields
    lineItems: lineItemModels,
    tracking: trackingEventModels,

    // derived fields
    pods: podEventModels,
    items: deriveItemsFromLineItems(lineItemModels), // Only present once consignment has been persisted in the back-end

    pallets,
    estimateId,
    orderId,
    dangerousGoodsDeclaration,
    issues,
    transferPolicy,
    linkedOrders,
    pickupRequests: pickupRequests || [],
  };
}

export const consignmentFactory = {
  create,
};
