import { Temporal } from '@js-temporal/polyfill';
import isEqual from 'lodash/isEqual';

import { plainDateNow } from '@/shared/DateTime/helpers';
import { instantFromDateAndTime } from '@/shared/DateTime/mappers';
import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import userMonitor from '@/app/instrumentation/userMonitor';
import { isPickupServiceRecommended } from '@/app/modules/Pickup/helpers/pickupService';

import { pickupInstrumentationTypes } from './types';
import { SavedFormData } from '@/app/modules/Pickup/types';
import { PickupServiceOption } from '@/shared/models';

instrumentation.on(pickupInstrumentationTypes.PICKUP_TIMEZONE_TOOLTIP, ({ type }) => {
  const context = { type };
  userMonitor.event('pickup timezone tooltip', context);
  FullStory.event('pickup timezone tooltip', context);
});

instrumentation.before(pickupInstrumentationTypes.PICKUP_CREATED, ({ prefillInitiatedFrom }) => ({
  prefillInitiatedFrom,
}));

instrumentation.on(
  pickupInstrumentationTypes.PICKUP_CREATED,
  ({
    formData,
    supplementalData,
    duration,
    initialData,
    prefillInitiatedFrom,
  }: SavedFormData & {
    duration: number;
    prefillInitiatedFrom: number;
  }) => {
    const { workflow } = supplementalData;
    const pickupLeadTimeDays = formData.pickupDate?.since(plainDateNow()).total({ unit: 'day' });
    const { pickupWindow } = formData.siteAccess;
    const pickupWindowLength = pickupWindow?.duration.total({ unit: 'minute' });

    let pickupLeadTimeMinutes = 0;
    if (pickupLeadTimeDays && formData.pickupDate && pickupWindow && formData.sender?.location.address) {
      const startOfPickupWindow = instantFromDateAndTime(
        formData.pickupDate,
        pickupWindow.start,
        formData.sender.location.address.timeZone,
      );
      pickupLeadTimeMinutes = startOfPickupWindow.since(Temporal.Now.instant()).total({ unit: 'minute' });
    }

    const destinations: Record<string, boolean> = {};
    const packagingTypes: Record<string, boolean> = {};
    let hasDGs = false;
    formData.freight.forEach(freight => {
      destinations[`${freight.destination.locality} ${freight.destination.postcode}`] = true;
      freight.items.forEach(item => {
        packagingTypes[item.packagingType] = true;
        if (item.dangerousGoods && item.dangerousGoods.length) hasDGs = true;
      });
    });

    const destinationCount = Object.entries(destinations).length;
    const packagingTypeCount = Object.entries(packagingTypes).length;

    const senderUpdated = !isEqual(formData.sender, initialData.sender);

    const preFillConsignmentCount = formData.preFillConsignmentIds?.length || 0;
    const preFillFreightAdded = workflow === 'prefill' && formData.freight.some(f => !f._isPreFill);
    const preFillServiceChanged =
      workflow === 'prefill' && formData.selectedService?.agreedServiceId !== supplementalData.preFillAgreedServiceId;

    const context = {
      // @context.pickup.pickupType (pickup)
      pickupType: formData.pickupType,

      // @context.pickup.workflow (facet)
      pickupWorkflow: workflow,

      // @context.pickup.pickupWorkflowInitiatedFrom (facet)
      pickupWorkflowInitiatedFrom: prefillInitiatedFrom,

      // @context.pickup.pickupLeadTimeDays (measure)
      pickupLeadTimeDays,

      // @context.pickup.pickupLeadTimeMinutes (measure)
      pickupLeadTimeMinutes,

      // @context.pickup.destinationCount (measure)
      destinationCount,

      // @context.pickup.packagingTypeCount (measure)
      packagingTypeCount,

      // @context.pickup.pickupWindowLength (measure)
      pickupWindowLength,

      // @context.pickup.senderUpdated (facet)
      senderUpdated,

      // @context.pickup.isClone (facet)
      isClone: workflow === 'clone',

      // @context.pickup.serviceWithoutEstimateSelected (facet)
      serviceWithoutEstimateSelected: !!formData.selectedService?.evaluation?.unavailablePricing?.length || false,

      // @context.pickup.preFillConsignmentCount (measure)
      preFillConsignmentCount,

      // @context.pickup.preFillFreightAdded (facet)
      preFillFreightAdded,

      // @context.pickup.preFillServiceChanged (facet)
      preFillServiceChanged,

      // @context.pickup.has_dg (facet)
      has_dg: hasDGs,
    };

    userMonitor.event('pickup created', {
      duration,
      pickup: context,
    });
    FullStory.event('pickup created', context, {
      pickupLeadTimeDays: 'int',
      pickupLeadTimeMinutes: 'int',
      destinationCount: 'int',
      packagingTypeCount: 'int',
      pickupWindowLength: 'int',
      preFillConsignmentCount: 'int',
    });
  },
);

instrumentation.before(pickupInstrumentationTypes.PICKUP_CREATED, ({ isClone }) => ({ isClone }));

instrumentation.on(
  pickupInstrumentationTypes.PICKUP_CREATE_SHOW_ALL_SERVICES,
  ({ visibleServiceCount, hiddenServiceCount }) => {
    const context = {
      visibleServiceCount,
      hiddenServiceCount,
    };
    userMonitor.event('pickup create show all services', context);
    FullStory.event('pickup create show all services', context, {
      visibleServiceCount: 'int',
      hiddenServiceCount: 'int',
    });
  },
);

instrumentation.on(
  pickupInstrumentationTypes.PICKUP_CREATE_SERVICES_REQUESTED,
  ({ services }: { services?: PickupServiceOption[] }) => {
    const eventContext = {
      services: {
        // @context.services.services_presented (measure)
        services_presented: services?.filter(isPickupServiceRecommended).length,
        // @context.services.services_unrated (measure)
        services_unrated: services?.filter(
          service => service.evaluation.unavailablePricing.length && service.selectable,
        ).length,
        // @context.services.services_unselectable (measure)
        services_unselectable: services?.filter(service => !service.selectable).length,
        // @context.services.services_total (measure)
        services_total: services?.length,
      },
    };

    userMonitor.event('pickup services requested', eventContext);
  },
);

instrumentation.on(pickupInstrumentationTypes.PICKUP_CREATE_ERROR, ({ error }) => {
  const context = {
    error,
  };
  userMonitor.event('pickup create error', context);
  FullStory.event('pickup create error', context);
});

instrumentation.on(pickupInstrumentationTypes.PICKUP_VIEW, ({ status }) => {
  const context = {
    pickup: {
      // @context.pickup.status (facet)
      status,
    },
  };
  userMonitor.event('pickup view', context);
  FullStory.event('pickup view', context);
});

instrumentation.on(pickupInstrumentationTypes.PICKUP_VIEW_CLONE, ({ status }) => {
  const context = {
    pickup: {
      // @context.pickup.status (facet)
      status,
    },
  };
  userMonitor.event('pickup view clone clicked', context);
  FullStory.event('pickup view clone clicked', context);
});

instrumentation.on(pickupInstrumentationTypes.PICKUP_CREATE_SERVICE_SELECTED, ({ service }) => {
  if (!service.value) return;

  if (service.value.evaluation.unavailablePricing.length) {
    FullStory.event('pickup service without estimate selected');
  }
});

instrumentation.on(
  pickupInstrumentationTypes.PICKUP_PREFILL_INITIATED,
  ({ type, consignmentIds, prefillInitiatedFrom }) => {
    FullStory.event(
      'pickup prefill',
      {
        type,
        consignments: consignmentIds,
        consignmentCount: consignmentIds.length,
        prefillInitiatedFrom,
      },
      {
        consignmentCount: 'int',
      },
    );
    userMonitor.event('pickup prefill', {
      type,
      consignmentIds,
      consignmentCount: consignmentIds.length,
      prefillInitiatedFrom,
    });
  },
);

instrumentation.on(pickupInstrumentationTypes.PICKUP_PREFILL_CUSTOM, ({ type }) => {
  FullStory.event('pickup prefill custom', { type });
  userMonitor.event('pickup prefill custom', { type });
});

instrumentation.on(pickupInstrumentationTypes.PICKUP_PREFILL_ERROR, ({ isValidationError, messages }) => {
  FullStory.event('pickup prefill error', { isValidationError, messages });
  userMonitor.event('pickup prefill error', { isValidationError, messages });
});

instrumentation.on(pickupInstrumentationTypes.PICKUP_CUTOFF_ERROR, ({ messages }) => {
  FullStory.event('pickup cutoff error', { messages });
  userMonitor.event('pickup cutoff error', { messages });
});
