import { createRouter, createWebHistory } from 'vue-router';

import config from '@/config';
import { routerOpenProps } from '@/shared/RouterOpen';

import carrierPickup from '@/app/carrierPickup';
import NotFoundLayout from '@/app/layout/views/NotFoundLayout.vue';
import { setPageTitleHook } from '@/shared/router/after-hooks';
import { clearAlerts } from '@/shared/router/before-hooks';
import { asyncImport } from '@/shared/router/helpers';

import { routes as AuthRoutes } from '@/app/modules/Auth';
import { routes as LegalRoutes } from '@/app/modules/Legal';
import { routes as SiteRoutes } from '@/app/modules/Site';

import { authenticationGuardFactory, contextGuardFactory } from './guards';

const routes = [
  // Home route
  {
    path: '',
    name: 'app.home',
    meta: { enableFullStory: true },
    redirect: { name: 'site.default' },
  },

  // /site/* and children
  ...SiteRoutes,

  // Routes for login, reset password, etc
  ...AuthRoutes,

  // Document viewer
  {
    path: '/document/:documentType/:documentId/:format?',
    name: 'document',
    component: asyncImport(() => import('@/app/layout/views/DocumentLayout.vue')),
    meta: {
      requiresAuthentication: true,
      title: 'View document',
      enableFullStory: false,
    },
    props: routerOpenProps,
  },

  // Pages
  ...LegalRoutes,

  ...carrierPickup.routes,

  // Error page catches all non-valid route requests
  // We could redirect here, but by providing only a component we display the
  // error at the path they requested
  {
    path: '/:notFound(.*)', // New catchall syntax must have a param name + regex
    name: 'app.error',
    component: NotFoundLayout,
    meta: {
      requiresAuthentication: false,
      title: 'Error',
      enableFullStory: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(config.baseUrl),
  // TODO GEPPIE-2338 emit a "loaded" event from views so we can handle to.hash
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0, left: 0 };
  },
  routes,
});

router.baseInstall = router.install;
router.hooksAttached = false;
router.install = app => {
  router.baseInstall(app);

  app.mixin({
    beforeCreate() {
      // Only attach router hooks once, presumably on the App component instance
      if (router.hooksAttached) return;
      router.hooksAttached = true;

      if (!this.$store) throw new Error('store plugin must be installed for authenticationGuardFactory');

      // guards registration
      const authGuard = authenticationGuardFactory(this.$store, router);
      router.beforeEach(authGuard);

      // context guard (site / org in URL)
      const contextGuard = contextGuardFactory(this.$store);
      router.beforeEach(contextGuard);

      // before hooks registration
      router.beforeEach(clearAlerts.bind());

      // after hooks registration
      router.afterEach(setPageTitleHook.bind(null, document, config.appTitle));
    },
  });
};

export default router;
