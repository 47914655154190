import { ref } from 'vue';

import { useOrgId } from '@/app/context';

import { ScopedStorage } from './types';
import useUserStorage from './useUserStorage';

const useOrgStorage = (manualOrgId?: string): ScopedStorage => {
  let orgId = ref(manualOrgId);
  if (!orgId.value) orgId = useOrgId(); // eslint-disable-line vue/no-ref-as-operand

  const userStorage = useUserStorage();

  const createKey = (suffix?: string) => {
    if (!orgId.value) throw new Error('Org ID is required to use org storage');
    return `org:${orgId.value}${suffix ? `:${suffix}` : ''}`;
  };

  const getItem = <T>(key: string) => userStorage.getItem<T>(createKey(key));

  const _getItems = () => {
    const orgKey = createKey();
    const userItems = userStorage._getItems();
    const items: Record<string, unknown> = {};
    Object.entries(userItems).forEach(([key, value]) => {
      if (key.startsWith(orgKey)) items[key.slice(orgKey.length + 1)] = value;
    });
    return items;
  };

  const setItem = (key: string, value: unknown) => userStorage.setItem(createKey(key), value);

  const removeItem = (key: string) => userStorage.removeItem(createKey(key));

  const clear = () => {
    const items = _getItems();
    Object.keys(items).forEach(key => removeItem(key));
  };

  return {
    createKey,
    getItem,
    _getItems,
    setItem,
    removeItem,
    clear,
  };
};

export default useOrgStorage;
