/**
 * OrgStore - action methods
 * @see https://vuex.vuejs.org/guide/actions.html
 */

import orgService from '@/shared/services/org';

import { latestLegalDate } from '@/app/modules/Legal/documents';
import { InternalTypes as Org } from '@/app/modules/Org/store/types';

const OrgActions = {
  [Org.actions.fetchUser]: async ({ commit }) => {
    const user = await orgService.fetchUser();

    if (!user) throw new Error('User is not valid');

    logger.debug('User accepted terms at', {
      acceptedTermsAt: user.acceptedTermsAt,
      latestTerms: latestLegalDate,
      hasAcceptedLatest: user.acceptedTermsAt > latestLegalDate,
    });

    // Persist the data in state
    commit(Org.mutations.SET_USER, user);
  },

  /**
   * Update the user's term acceptance date when it has been updated independently.
   */
  [Org.actions.acceptTerms]: async ({ commit }) => {
    const acceptTime = await orgService.setAcceptTerms();
    commit(Org.mutations.SET_USER_ACCEPT_TERMS, { acceptTime });
  },

  /**
   * When the user logs out, clear their data from the Org store.
   */
  [Org.actions.clearOrgData]: async ({ commit }) => {
    commit(Org.mutations.SET_USER, null);
  },
};

export default OrgActions;
