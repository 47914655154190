import { FullStory as FS, init } from '@fullstory/browser';
import { type PublicLogLevel } from '@fullstory/snippet';
import { App } from 'vue';
import { Router } from 'vue-router';

import { getConfig } from '@/shared/init';

const config = getConfig();

declare global {
  interface Window {
    _fs_ready: () => void;
  }
}

interface FullStoryOptions {
  enabled: boolean;
  orgId: string;
  globalUserVars?: Record<string, unknown>;
}

class FullStory {
  private _enabled: boolean;
  private _orgId: string;
  private _globalUserVars: object;
  private _uid?: string;
  private _userVars: object;
  private _fs_ready: boolean;
  private _has_identified: boolean;
  private _prevent_identify: boolean;
  private _context: Record<string, unknown>;

  constructor(options: FullStoryOptions) {
    const { enabled, orgId, globalUserVars } = options;

    this._enabled = enabled;
    this._orgId = orgId;
    this._globalUserVars = globalUserVars || {};

    this._uid = undefined;
    this._userVars = {};

    this._fs_ready = false;
    this._has_identified = false;
    this._prevent_identify = false;

    this._context = {};

    if (this._enabled) {
      this.init();
    }
  }

  init() {
    if (!this._enabled) return;

    // once the client is initialised and ready to record
    // log the session to correlate with datadog session
    // https://developer.fullstory.com/callbacks-and-delegates
    window._fs_ready = () => {
      this._fs_ready = true;
      this.attachToLogger();
    };

    // fs.init({ orgId: this._orgId, debug: true });
    init({ orgId: this._orgId });

    // off by default unless explicitly enabled by route meta prop
    // meta: { enableFullStory: true }
    FS('shutdown'); // !important
  }

  identify(uid?: string, userVars?: object) {
    // > Careful! You can't re-identify someone once you've given them a unique id with FS.identify.
    // > Attempting to call FS.identify on an already-identified user will split the session and create a new, separate user.
    // > "you should only call FS.identify after a user has successfully authenticated into your application"

    // https://help.fullstory.com/hc/en-us/articles/360020623234#Custom%20Property%20Rate%20Limiting

    // For FS.setUserVars/FS.identify, the burst limit is 5 calls per second and the sustained limit is 12 calls per minute.
    // If these limits are exceeded for a given session,
    // subsequent Recording Client API calls for that session will be not be recorded in FullStory.

    if (!this._enabled) return;
    if (this._has_identified) return;

    this._uid = uid || this._uid;
    this._userVars = userVars || this._userVars;

    if (this._prevent_identify) return;
    if (!this._uid) return;

    FS('setIdentity', {
      uid: this._uid,
      properties: {
        ...this._globalUserVars,
        ...this._userVars,
      },
    });
    this._has_identified = true;

    logger.addGlobalContext('fullstory.session', this.getCurrentSessionURL());
    logger.info('fullstory identify');
  }

  setUserVars(userVars = {}) {
    if (!this._enabled) return;

    // > the burst limit is 5 calls per second
    // if this becomes a problem, there is a debounced version of this function
    // in git history, however we should consider whether we can gather all user
    // vars before calling this instead of sending many setUserVars calls
    FS('setProperties', {
      type: 'user',
      properties: {
        ...this._globalUserVars,
        ...userVars,
      },
    });
  }

  /**
   * Add contextual data that will be included with every action recorded.
   * @param {string} key - a set of key/value data relating to the entire user session
   * @param value - a set of key/value data relating to the entire user session
   */
  addContext(key: string, value: unknown) {
    // Fold the new context key/values but preserve non-conflicting existing context
    this._context = {
      ...this._context,
      [key]: value,
    };
    logger.debug('FullStory.context', this._context);
  }

  removeContext(key: string) {
    if (typeof this._context[key] !== 'undefined') {
      delete this._context[key];
    }
    logger.debug('FullStory.context', this._context);
  }

  anonymize() {
    if (!this._enabled) return;

    this._uid = undefined;
    this._userVars = {};
    this._context = {};

    FS('setIdentity', { anonymous: true });
    this._has_identified = false;

    // after calling fs.anonymize, the session has been split and a new session is available
    logger.addGlobalContext('fullstory.session', this.getCurrentSessionURL());
  }

  consent(consent: boolean) {
    if (!this._enabled) return;

    FS('setIdentity', { consent });
  }

  shutdown() {
    this._prevent_identify = true;

    if (!this._enabled) return;

    FS('shutdown');
  }

  restart() {
    if (!this._enabled) return;

    if (this._prevent_identify) {
      this._prevent_identify = false;
      this.identify();
    }

    FS('restart');
  }

  log({ level, message }: { level?: PublicLogLevel; message: string }) {
    if (!this._enabled) return;

    FS('log', { level, msg: message });
  }

  event(name: string, properties?: object, schema?: object) {
    const payload = {
      name,
      properties: {
        ...this._context,
        ...properties,
      },
      ...(schema
        ? {
            properties: schema,
          }
        : {}),
    };

    if (config.debug) logger.debug('FullStory.event', payload);
    if (!this._enabled) return;

    FS('trackEvent', payload);
  }

  getCurrentSessionURL(withTimestamp?: boolean) {
    if (!this._enabled) return null;
    if (!this._fs_ready) return null;

    const format = withTimestamp ? 'url.now' : 'url';
    return FS('getSession', { format });
  }

  attachToLogger() {
    logger.addGlobalContext('fullstory.session', this.getCurrentSessionURL());
    logger.addContextMutator(this.addLogURL.bind(this));
  }

  addLogURL(logContext: Record<string, unknown>) {
    const ctx = { ...logContext };

    ctx['fullstory.timestamp'] = this.getCurrentSessionURL(true);

    return ctx;
  }

  install(app: App, options: { router?: Router } = {}) {
    // VueRouter integration
    // only set up the guard if FullStory is enabled
    if (this._enabled && options.router) {
      options.router.beforeEach((to, from, next) => {
        // calls to restart() and shutdown() can be made multiple times idempotently
        if (to.meta.enableFullStory) {
          logger.debug('FullStory RESTART', to.name);
          this.restart();
        } else {
          logger.debug('FullStory SHUTDOWN', to.name);
          this.shutdown();
        }

        next();
      });
    }
  }
}

export default FullStory;
