export enum ConsignmentInstrumentation {
  /* Service Selection */
  CONSIGNMENT_CREATED = 'consignment created',
  CONSIGNMENT_DETAILS_EXPANDED = 'consignment details expanded',
  /* Service Selection */
  QUOTES_REQUESTED = 'quotes requested',
  QUOTE_SELECTION_FINALISED = 'quote selection finalised',
  QUOTE_SELECTED = 'quote selected',

  ENQUIRY_STARTED = 'enquiry started',
  ENQUIRY_CREATED = 'enquiry created',

  CONSIGNMENT_UPDATED = 'consignment updated',

  ADDRESS_MODE_CHANGE = 'address mode change',

  CONSIGNMENT_AUTOPRINT_LABEL_COPIES_UPDATED = 'consignment autoprint label copies updated',

  /* consolidation */
  /* OC site feature not enabled but suggestions available */
  CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_MISSED = 'consignment consolidation opportunity missed',
  CONSIGNMENT_CONSOLIDATION_SUGGESTIONS_PRESENTED = 'consignment consolidation suggestions presented',
  CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_REJECTED = 'consignment consolidation opportunity rejected',
  CONSIGNMENT_CONSOLIDATION_DISCARDED = 'consignment consolidation discarded',
  CONSIGNMENT_PRESELECTED_SERVICE_QUOTE_UPDATED = 'consignment preselected service quote updated',

  TODAYS_DISPATCH_DATE_UPDATED = "today's dispatch date saved",
}

export default ConsignmentInstrumentation;
