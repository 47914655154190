import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import userMonitor from '@/app/instrumentation/userMonitor';

import { OrderInstrumentation } from '@/app/modules/Order/instrumentation/types';
import { OrderSummary } from '@/shared/models/Order/Order';

instrumentation.on(
  OrderInstrumentation.SENDER_ADDRESS_MISMATCH,
  ({ userAction, orderId }: { userAction: string; orderId: string }) => {
    userMonitor.event('order sender address does not match site address', {
      userAction,
      orderId,
    });

    FullStory.event('order sender address does not match site address', {
      userAction,
      orderId,
    });
  },
);

instrumentation.on(
  OrderInstrumentation.ORDER_ALREADY_CONSIGNED,
  ({
    orderId,
    linkedConsignments,
    userAction,
  }: {
    orderId: string;
    linkedConsignments: OrderSummary['linkedConsignments'];
    userAction: string;
  }) => {
    const linkedConsignmentsManifested = linkedConsignments?.filter(({ manifested }) => !!manifested).length;
    const linkedConsignmentsUnmanifested = linkedConsignments?.filter(({ manifested }) => !manifested).length;
    userMonitor.event('selected order has been already consigned', {
      orderId,
      linkedConsignmentsManifested,
      linkedConsignmentsUnmanifested,
      userAction,
    });

    FullStory.event('selected order has been already consigned', {
      orderId,
      linkedConsignmentsManifested,
      linkedConsignmentsUnmanifested,
      userAction,
    });
  },
);

instrumentation.on(OrderInstrumentation.ORDER_SELECTED, ({ orderId, duration }) => {
  userMonitor.event('order selected', {
    orderId,
    duration,
  });

  FullStory.event('order selected', {
    orderId,
    duration,
  });
});

// TODO GEPPIE-11076 this event needs to be reinstated as it was lost during consignment refactor.
// instrumentation.on(OrderInstrumentation.REPORT_ORDER_VALIDATION_ERRORS, ({ orderId, validations }) => {
//   const errors = validations.$errors;

//   const errorsWhitelist = ['dangerousGoodsDeclaration'];

//   const invalidFields = errors.reduce((acc, error) => {
//     if (!errorsWhitelist.includes(error.$property)) {
//       acc[error.$property] = error.$message;
//     }
//     return acc;
//   }, {});

//   userMonitor.event('order validations', {
//     orderId,
//     invalidFields,
//     noErrors: errors.length === 1 && errors[0].$property === 'dangerousGoodsDeclaration',
//   });

//   FullStory.event('order validations', {
//     orderId,
//     invalidFields,
//     noErrors: errors.length === 1 && errors[0].$property === 'dangerousGoodsDeclaration',
//   });
// });
