import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';
import userMonitor from '@/app/instrumentation/userMonitor';
import { rcspInstrumentationTypes } from '@/app/modules/SitePreferences/instrumentation/types';

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"rcsp created"
instrumentation.on(rcspInstrumentationTypes.RCSP_CREATED, () => {
  userMonitor.event(rcspInstrumentationTypes.RCSP_CREATED);
  FullStory.event(rcspInstrumentationTypes.RCSP_CREATED, {});
});

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"rcsp updated"
instrumentation.on(rcspInstrumentationTypes.RCSP_UPDATED, () => {
  userMonitor.event(rcspInstrumentationTypes.RCSP_UPDATED);
  FullStory.event(rcspInstrumentationTypes.RCSP_UPDATED, {});
});

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"rcsp deleted"
instrumentation.on(rcspInstrumentationTypes.RCSP_DELETED, () => {
  userMonitor.event(rcspInstrumentationTypes.RCSP_DELETED);
  FullStory.event(rcspInstrumentationTypes.RCSP_DELETED, {});
});

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"rcsp deleted"
instrumentation.on(rcspInstrumentationTypes.RCSP_BULK_DELETED, ({ policiesDeleted }: { policiesDeleted: number }) => {
  // facet: @context.deletion_count
  userMonitor.event(rcspInstrumentationTypes.RCSP_BULK_DELETED, { deletion_count: policiesDeleted });
  FullStory.event(rcspInstrumentationTypes.RCSP_BULK_DELETED, { policiesDeleted });
});

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"rcsp enabled"
instrumentation.on(rcspInstrumentationTypes.RCSP_ENABLED, () => {
  userMonitor.event(rcspInstrumentationTypes.RCSP_ENABLED);
  FullStory.event(rcspInstrumentationTypes.RCSP_ENABLED, {});
});

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"rcsp disabled"
instrumentation.on(rcspInstrumentationTypes.RCSP_DISABLED, () => {
  userMonitor.event(rcspInstrumentationTypes.RCSP_DISABLED);
  FullStory.event(rcspInstrumentationTypes.RCSP_DISABLED, {});
});
