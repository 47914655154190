import { asyncImport, withContextPrefix } from '@/shared/router/helpers';

const autoprintRoutes = [
  ...withContextPrefix(
    {
      path: 'autoprint',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: 'config',
        name: 'autoprint.config',
        component: asyncImport(() => import('@/app/modules/AutoPrint/views/AutoprintConfig.vue')),
        meta: {
          title: 'Printer configuration',
          enableFullStory: true,
          context: { allowed: ['site', 'org'] },
        },
      },
      {
        path: '',
        redirect: { name: 'autoprint.config' },
      },
    ],
  ),
];

export default autoprintRoutes;
