/**
 * ConsignmentSummary model
 * represents a single API result when searching/listing consignments
 */

import type {
  ConsignmentManifest,
  ConsignmentTransfer,
  ConsignmentType,
} from '@/shared/models/Consignment/Consignment';

import DeliveryTimeSlot from '@/shared/models/DeliveryTimeSlot';

import { components } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';
import { ConsignmentPalletDocketNumbers, PalletCounts, PalletTransferType } from '@/shared/types/ConsignmentPallets';

import {
  ConsignmentProofOfDeliveryEvent,
  ConsignmentTrackingEvent,
  consignmentTrackingEventFactory,
  isProofOfDeliveryEvent,
} from './ConsignmentTrackingEvent';
import { ConsignmentPickupRequest } from './ConsignmentPickupRequest';

export interface ConsignmentSummaryAddressee {
  name: string;
  address: components['schemas']['Address'] | components['schemas']['UnverifiedAddress'];
}

export type ConsignmentSummarySender = ConsignmentSummaryAddressee;
export type ConsignmentSummaryReceiver = ConsignmentSummaryAddressee & {
  specialInstructions?: string;
  deliveryTimeSlot?: DeliveryTimeSlot;
  deliveryReferences: string[];
};

/**
 * Simplified version of a consignment useful for search/listing
 */
interface ConsignmentSummary {
  id: string;
  type: ConsignmentType;
  consignmentNo: string;
  previousConsignmentNumbers?: string[];
  updated: RFC3339InstantString;
  dispatchDate: PlainDateString;
  estimatedTimeOfArrival?: RFC3339InstantString;
  references: string[];
  itemQuantity: number;
  volume: number;
  weight: number;
  site: {
    id: string;
  };
  manifest?: ConsignmentManifest;
  transfer?: ConsignmentTransfer;
  transferPolicy: components['schemas']['ConsignmentTransferPolicy'];
  agreedService: {
    id: string;
    name: string;
  };
  sender: ConsignmentSummarySender;
  receiver: ConsignmentSummaryReceiver;
  organisation: {
    id: string;
  };
  carrier: {
    id: string;
    name: string;
  };
  tracking: ConsignmentTrackingEvent[];
  pallets:
    | {
        transferType: PalletTransferType;
        quantity?: PalletCounts;
        docketNumbers?: ConsignmentPalletDocketNumbers;
      }
    | undefined;
  hasDangerousGoods: boolean;
  issues?: Record<string, number>;

  // All pickup requests associated with this consignment's freight.
  pickupRequests: ConsignmentPickupRequest[];

  // ### DERIVED PROPERTIES
  pods: ConsignmentProofOfDeliveryEvent[];
}

export default ConsignmentSummary;

function create({
  id,
  type,
  consignmentNo,
  previousConsignmentNumbers = [],
  updated,
  dispatchDate,
  estimatedTimeOfArrival,
  references = [],
  itemQuantity,
  volume,
  weight,
  organisation,
  site,
  carrier,
  manifest,
  transfer,
  transferPolicy,
  agreedService,
  sender,
  receiver,
  tracking,
  pallets,
  hasDangerousGoods,
  issues,
  pickupRequests,
}: Omit<ConsignmentSummary, 'pods'>): ConsignmentSummary {
  const trackingEventModels = tracking?.map(consignmentTrackingEventFactory.create) || [];
  const podEventModels = trackingEventModels.filter(isProofOfDeliveryEvent);

  return {
    id,
    type,
    consignmentNo,
    previousConsignmentNumbers,
    updated,
    dispatchDate,
    estimatedTimeOfArrival,
    references,
    itemQuantity,
    volume,
    weight,
    organisation,
    site,
    carrier,
    manifest,
    transfer,
    transferPolicy,
    agreedService,
    sender,
    receiver,
    pallets,
    hasDangerousGoods,
    issues,
    tracking: trackingEventModels,
    pods: podEventModels,
    pickupRequests,
  };
}

const createFromApi = ({ id, attributes }: { id: string; attributes: Omit<ConsignmentSummary, 'id'> }) =>
  create({
    id,
    ...attributes,
  });

export const consignmentSummaryFactory = {
  create,
  createFromApi,
};
