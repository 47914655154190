import VueInstrumentation from '@/shared/instrumentation/VueInstrumentation';

import userMonitor from '@/app/instrumentation/userMonitor';

import { ConsignmentInstrumentation } from '@/app/modules/Consignment/instrumentation/types';

export function registerHandlers(instrumentation: VueInstrumentation) {
  instrumentation.before(ConsignmentInstrumentation.ENQUIRY_CREATED, () => {
    userMonitor.event('enquiry started', {});
  });

  // TODO this should be store action name here
  instrumentation.on(ConsignmentInstrumentation.ENQUIRY_CREATED, ({ duration, enquiry }) => {
    userMonitor.event('enquiry submitted', {
      duration,
      enquiry: {
        consignmentId: enquiry.consignmentId,
        subject: enquiry.subject,
      },
    });
  });
}
