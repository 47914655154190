import { type ComponentPublicInstance } from 'vue';

import app from '@/config';

export default function handleErrorCaptured(error: unknown, instance: ComponentPublicInstance | null, info: string) {
  if (!(error instanceof Error)) {
    logger.warn('Non-Error object received by handleErrorCaptured', { error });
    return;
  }

  // ignore route navigation abort errors which can be thrown from valid app logic
  // aborting navigation, ie data loss warning.
  if (error.message.match(/navigation aborted from .* navigation guard/i)) {
    return;
  }

  if (import.meta.env?.NODE_ENV === 'development' && info?.includes('render')) {
    logger.warn(
      `render errors cannot be handled properly by the errorHandling system in development mode.
@see https://github.com/vuejs/core/blob/ae4b0783d78670b6e942ae2a4e3ec6efbbffa158/packages/runtime-core/src/errorHandling.ts#L157-L161 for why.`,
      {},
    );
  }

  /* eslint-disable no-console */
  if (app.debug && !window.gep.session.isTest) {
    // We really want to get a useful source-mapped error, and a stack trace in the browser when in development mode.
    console.group('[handleErrorCaptured]', info);
    console.error(error);
  }

  // Follow the component hairarchy upwards through each parent, looking for errorHandle hooks.
  try {
    let component = instance;
    while (component) {
      // $.errorHandle is attached to component instances by useErrorBoundary composable
      if (component.$.errorHandle) {
        const capture = component.$.errorHandle.call(component, error, instance, info) === false;
        if (capture) return;
      }

      component = component.$parent;
    }
  } catch (e) {
    logger.error('[handleErrorCaptured] error handling aborted', e);
  } finally {
    if (app.debug && !window.gep.session.isTest) {
      console.groupEnd();
    }
  }
  /* eslint-enable */
}
