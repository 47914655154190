import omit from 'lodash/omit';

import { Address, createAddress } from '@/shared/models';
import { components } from '@/shared/services/schema/geppetto-address-api/addresses.schema';
import { ADDRESS_FROM } from '@/shared/models/Address';

export type AddressApiData = components['schemas']['AddressData'];

const AddressMapper = {
  fromAPI(data: Partial<AddressApiData>): Address {
    const address: Address = {
      ...omit(data.attributes, 'from'),
    };
    if (data.attributes?.from === 'estimate') {
      address.from = ADDRESS_FROM.estimate;
    }
    if (data.attributes?.from === 'suggestion') {
      address.from = ADDRESS_FROM.suggestion;
    }
    return createAddress(address);
  },
};

export default {
  Address: AddressMapper,
};
