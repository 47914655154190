<template>
  <AppLayout>
    <NotFound />
  </AppLayout>
</template>

<script>
  import AppLayout from '@/app/layout/views/AppLayout.vue';

  import NotFound from './NotFound.vue';

  export default {
    name: 'NotFoundLayout',
    components: { NotFound, AppLayout },
  };
</script>
