import { Temporal } from '@js-temporal/polyfill';

import { DateRange, TimeRange } from '@/shared/DateTime';

import DeliveryTimeSlot, { NewDeliveryTimeSlot } from '../DeliveryTimeSlot';

export function mapDTSoNewDTS(
  timeZone?: string,
  deliveryTimeSlot?: DeliveryTimeSlot,
  deliveryReferences?: string[],
): NewDeliveryTimeSlot {
  return {
    slot:
      deliveryTimeSlot && timeZone
        ? {
            ...mapDTSWindowToRanges(deliveryTimeSlot, timeZone),
            references: deliveryReferences,
          }
        : undefined,
    requiresDTS: !!deliveryTimeSlot,
  };
}

export function mapDTSWindowToRanges(
  deliveryTimeSlot: DeliveryTimeSlot,
  timeZone: string,
): {
  dateRange: DateRange;
  timeRange: TimeRange;
} {
  const { window, recurrences } = deliveryTimeSlot;
  const startDate = window.start.toZonedDateTimeISO(timeZone);
  const endDate = startDate.add({ days: recurrences });

  return {
    dateRange: !recurrences
      ? [Temporal.PlainDate.from(startDate)]
      : [Temporal.PlainDate.from(startDate), Temporal.PlainDate.from(endDate)],
    timeRange: TimeRange.from({
      start: Temporal.PlainTime.from(deliveryTimeSlot.window.start.toZonedDateTimeISO(timeZone).toPlainTime()),
      end: Temporal.PlainTime.from(deliveryTimeSlot.window.end.toZonedDateTimeISO(timeZone).toPlainTime()),
    }),
  };
}
