import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { ref, watch, WatchStopHandle } from 'vue';
import { plainDateNow } from '@/shared/DateTime/helpers';
import FullStory from '@/shared/FullStory';
import {
  ConsignmentAutoprintPreference,
  ConsignmentConsolidationSuggestion,
  MovementFlowType,
  NewDeliveryTimeSlot,
  PrintClientDetails,
  PrintClientStatus,
  PrintConfig,
} from '@/shared/models';
import userMonitor from '@/app/instrumentation/userMonitor';
import getAutoprintDefaultSelection from '@/app/modules/AutoPrint/domain/getAutoprintDefaultSelection';
import { getConsignmentStatus } from '@/app/modules/Consignment/helpers';
import { ConsignmentInstrumentation } from '@/app/modules/Consignment/instrumentation/types';
import ConsignmentFormData from '@/shared/models/Consignment/ConsignmentFormData';
import {
  AutoprintContext,
  ConsignmentAddresseeChanges,
  ConsignmentCreatedContext,
  ConsignmentCreatedPayload,
  ConsignmentUpdatedPayload,
  QuoteContext,
} from '@/app/modules/Consignment/instrumentation/contextTypes';
import { AddresseeFormData } from '@/shared/models/AddresseeFormData';
import ConsignmentFormSupplementalData from '@/shared/models/Consignment/ConsignmentFormSupplementalData';
import { ConsignmentFormStore } from '@/app/modules/Consignment/store/consignmentFormStore';
import { Temporal } from '@js-temporal/polyfill';
import { isQuoteRanked, isQuoteWithPrices } from '@/shared/models/Quote/QuoteWithService';

import './enquiry';
import './serviceSelection';
import VueInstrumentation from '@/shared/instrumentation/VueInstrumentation';

function getQuotesContext(
  formData: ConsignmentFormData,
  supplementalData: ConsignmentFormSupplementalData,
  quoteInteractions: number,
): QuoteContext {
  const quotes = supplementalData.quotes || [];
  const sortedQuoteRanks = [...quotes]
    .filter(isQuoteRanked)
    .sort((a, b) => a.price.rank - b.price.rank)
    .map(q => q.price.rank);

  const sortedQuoteTotals = [...quotes]
    .filter(isQuoteWithPrices)
    .sort((a, b) => a.price.cost.total - b.price.cost.total)
    .map(q => q.price.cost.total);

  const selectedQuote = quotes.find(quote => quote.id === formData.quoteId);

  if (!selectedQuote) {
    return { cheapest: false, most_expensive: false, isMasked: false };
  }

  const selectedTotal = selectedQuote.price.cost?.total;

  const isSelectedQuoteWithoutPrice = !!selectedQuote.evaluation.unavailablePricing;
  const quotesContext: QuoteContext = {
    // @context.quotes.cheapest (facet)
    cheapest: selectedQuote.price.rank === 0,
    // @context.quotes.most_expensive (facet)
    most_expensive:
      isQuoteRanked(selectedQuote) && sortedQuoteRanks.length > 1
        ? !sortedQuoteRanks.find(t => t > selectedQuote.price.rank)
        : false,
    difference_from_cheapest:
      !isSelectedQuoteWithoutPrice && selectedTotal ? selectedTotal - sortedQuoteTotals[0] : null,
    // @context.quotes.quotes_interactions (measure)
    quotes_interactions: quoteInteractions,
    // @context.quotes.unavailable_pricing (facet)
    unavailable_pricing: isSelectedQuoteWithoutPrice,
    // if any quote has the 'unsupportedByReceiver' evaluation, then an RCSP policy is in play.
    // @context.quotes.rcsp (facet)
    rcsp: quotes.some(quote => quote.evaluation.unsupportedByReceiver),
    isMasked: selectedQuote.price.isMasked,
  };

  const previousQuote = supplementalData.previousQuote;

  if (previousQuote) {
    // @context.quotes.agreed_service_changed (facet)
    quotesContext.agreed_service_changed = selectedQuote.agreedServiceId === previousQuote.agreedServiceId;
  }

  if (supplementalData.previousAgreedService?.id) {
    // @context.quotes.service_from_order_changed
    quotesContext.service_from_order_changed =
      selectedQuote.agreedServiceId !== supplementalData.previousAgreedService.id;
  }

  return quotesContext;
}

function addresseeChanges(before: AddresseeFormData, after: AddresseeFormData): ConsignmentAddresseeChanges {
  return {
    // @consignment.changed.*.contact_name
    contact_name: before.contact.name !== after.contact.name,
    // @consignment.changed.*.contact_phone
    contact_phone: before.contact.phone !== after.contact.phone,
    // @consignment.changed.*.contact_emails
    contact_email: before.contact.email !== after.contact.email,
    // @consignment.changed.*.line2
    line2: before.location.line2 !== after.location.line2,
    // Only include these fields if they're set in the data
    // @consignment.changed.*.special_instructions
    ...(after.deliveryInstructions?.specialInstructions && {
      special_instructions:
        before.deliveryInstructions?.specialInstructions !== after.deliveryInstructions?.specialInstructions,
    }),
  };
}

function consignmentChangedKeys(current: ConsignmentFormData, initial: ConsignmentFormData) {
  return {
    // @context.consignment.changed.dispatch_date (Facet)
    dispatch_date: initial.dispatchDate !== current.dispatchDate,
    // @context.consignment.changed.references (Facet)
    references: !isEqual(sortBy(initial.references), sortBy(current.references)),
    billing: {
      // @consignment.changed.billing.cost_centre
      cost_centre: (!!current.costCenter || !!initial.costCenter) && initial.costCenter !== current.costCenter,
      // @consignment.changed.billing.payer_account
      payer_account:
        (!!current.payerAccount || !!initial.payerAccount) && initial.payerAccount !== current.payerAccount,
    },
    // @consignment.changed.receiver.* (Facets)
    receiver: addresseeChanges(initial.receiver, current.receiver),
    // @consignment.changed.sender.* (Facets)
    sender: addresseeChanges(initial.sender, current.sender),
    lineItems: {
      // @context.consignment.changed.lineItems.added (Measure)
      added: current.lineItems.filter(item => !item.id).length,
      // @context.consignment.changed.lineItems.updated (Measure)
      updated: current.lineItems
        .filter(item => !!item.id)
        .reduce((total, item) => {
          const existing = find(initial.lineItems, { id: item.id });
          if (existing && item.quantity !== existing.quantity) return total + 1;
          return total;
        }, 0),
      // @context.consignment.changed.lineItems.removed (Measure)
      removed: differenceBy(initial.lineItems, current.lineItems, 'id').length,
    },
  };
}

const getAutoprintInstrumentationContext = ({
  printConfig,
  printClientDetails,
  autoprintPreference,
}: {
  printConfig?: PrintConfig;
  printClientDetails?: PrintClientDetails;
  autoprintPreference: ConsignmentAutoprintPreference;
}): AutoprintContext => {
  const autoprintDefaults = getAutoprintDefaultSelection(printConfig, printClientDetails?.status);

  return {
    clientConfig: {
      enabled: printConfig?.enabled || false,
      defaultConnotePrinter: {
        enabled: printConfig?.defaultConnotePrinter.enabled || false,
        id: printConfig?.defaultConnotePrinter.id,
      },
      defaultLabelPrinter: {
        enabled: printConfig?.defaultLabelPrinter.enabled || false,
        id: printConfig?.defaultLabelPrinter.id,
      },
    },
    // @context.consignment.autoprint.clientStatus (facet)
    clientStatus: printClientDetails?.status,
    // @context.consignment.autoprint.clientRunning (facet)
    clientRunning: printClientDetails?.status === PrintClientStatus.running, // derived information
    // @context.consignment.autoprint.printLabelsDefault (facet)
    printLabelsDefault: autoprintDefaults.labels,
    // @context.consignment.autoprint.labelsPrinted (facet)
    labelsPrinted: autoprintPreference.labels,
    // @context.consignment.autoprint.labelsPrintCopies (Measure)
    labelsPrintCopies: autoprintPreference.labelCopies,
    // @context.consignment.autoprint.printConnoteDefault (facet)
    printConnoteDefault: autoprintDefaults.connote,
    // @context.consignment.autoprint.connotesPrinted (facet)
    connotesPrinted: autoprintPreference.connote,
  };
};

const getDTSContext = (dts?: NewDeliveryTimeSlot): ConsignmentCreatedContext['deliveryTimeSlot'] => {
  let dateRangeDays: number | undefined = undefined;
  if (dts?.requiresDTS && dts?.slot?.dateRange) {
    dateRangeDays = dts?.slot?.dateRange[0].until(dts?.slot?.dateRange[1] || dts?.slot?.dateRange[0]).days;
  }

  let timeRangeMinutes: number | undefined = undefined;
  if (dts?.requiresDTS && dts?.slot?.timeRange) {
    timeRangeMinutes = dts.slot.timeRange.duration.minutes;
  }

  return {
    selected: dts?.requiresDTS || false,
    dateRangeDays,
    // @context.consignment.deliveryTimeSlot.timeRangeMinutes
    timeRangeMinutes,
  };
};

export function registerHandlers(instrumentation: VueInstrumentation) {
  const quoteInteractions = ref(0);
  let quoteWatcherStop: WatchStopHandle;

  instrumentation.before(
    ConsignmentInstrumentation.CONSIGNMENT_CREATED,
    ({ formStore }: { formStore: ConsignmentFormStore }) => {
      if (quoteWatcherStop) quoteWatcherStop();

      quoteInteractions.value = 0;
      quoteWatcherStop = watch(
        () => formStore.formData.value.quoteId,
        () => {
          quoteInteractions.value += 1;
          logger.debug('quote updated', { quoteInteractions });
        },
      );
    },
  );

  instrumentation.on(
    ConsignmentInstrumentation.CONSIGNMENT_CREATED,
    ({ formData, supplementalData, initialData, duration }: ConsignmentCreatedPayload) => {
      const today = plainDateNow();
      const diff = today.until(formData.dispatchDate!);

      // Collect user metrics about how consignments are created, what gets used and changed
      const consignmentContext: ConsignmentCreatedContext = {
        changed: consignmentChangedKeys(formData, initialData),
        dispatch_delta_days: diff.total('days'),
        items: {
          with_description: formData.lineItems.filter(item => !!item.description).length,
          with_reference: formData.lineItems.filter(item => !!item.reference).length,
          with_dg: formData.lineItems.filter(item => item.dangerousGoods && item.dangerousGoods.length).length,
        },
        reference_count: formData.references.length || 0,
        type: supplementalData.type,
        deliveryTimeSlot: getDTSContext(formData.receiverDeliveryTimeSlot),
        // @context.consignment.isConvertedEstimate
        isConvertedEstimate: !!supplementalData.estimateId,
        // @context.consignment.isConvertedOrder
        isConvertedOrder: !!supplementalData.orderId,
        autoprint: getAutoprintInstrumentationContext({
          printConfig: supplementalData.autoprintConfig?.config,
          printClientDetails: supplementalData.autoprintConfig?.client,
          autoprintPreference: supplementalData.autoprintPreferences,
        }),
        has_dg: formData.lineItems.some(item => item.dangerousGoods && item.dangerousGoods.length),
        sender: {
          address_book_entry: !!formData.sender.addressBookEntryId,
          address_type: formData.sender.location.address?.from,
        },
        receiver: {
          address_book_entry: !!formData.receiver.addressBookEntryId,
          address_type: formData.receiver.location.address?.from,
        },
      };

      if (formData.pallets?.transferType) {
        consignmentContext.pallets = formData.pallets?.transferType
          ? {
              // @context.consignment.pallets.transferType
              transferType: formData.pallets.transferType,
              // @context.consignment.pallets.transferTypeOverridden
              transferTypeOverridden:
                supplementalData.preferredPalletTransferType &&
                formData.pallets.transferType !== supplementalData.preferredPalletTransferType,
              // @context.consignment.pallets.palletCount.(chep|loscam|other)
              palletCount: formData.lineItems.reduce(
                (acc, lineItem) => {
                  Object.entries(lineItem.pallets || {}).forEach(([lender, count]) => {
                    acc[lender] = (acc[lender] || 0) + count;
                  });
                  return acc;
                },
                {} as Record<string, number>,
              ),
              // @context.consignment.pallets.docketCount.(chep|loscam|other)
              docketCount: Object.entries(formData.pallets?.docketNumbers || {}).reduce(
                (acc, [lender, docketNumbers]) => ({
                  ...acc,
                  [lender]: docketNumbers?.length || 0,
                }),
                {},
              ),
            }
          : undefined;
      }

      const quotesContext = getQuotesContext(formData, supplementalData, quoteInteractions.value);

      userMonitor.event('consignment created', {
        duration,
        consignmentId: supplementalData.consignmentId,
        quoteSetId: supplementalData.quotes?.at(0)?.quoteSetId, // TODO check what to do here
        quoteId: formData.quoteId,
        consignment: consignmentContext,
        quotes: quotesContext,
      });

      FullStory.event('consignment created', {
        consignmentId: supplementalData.consignmentId,
        requiresDTS: !!formData.receiverDeliveryTimeSlot?.requiresDTS,
        hasPallets: !!consignmentContext.pallets,
        hasDGs: consignmentContext.items.with_dg > 0,
      });
    },
  );

  instrumentation.on(
    ConsignmentInstrumentation.CONSIGNMENT_UPDATED,
    ({ formData, supplementalData, initialData, consolidation }: ConsignmentUpdatedPayload) => {
      userMonitor.event('consignment updated', {
        consignmentId: supplementalData.consignmentId,
        quoteSetId: supplementalData.quotes?.at(0)?.quoteSetId, // TODO review this
        quoteId: formData?.quoteId,
        consignment: {
          // @context.consignment.isConsolidatedFromOrder
          isConsolidatedFromOrder: !!supplementalData.orderId,
          changed: consignmentChangedKeys(formData, initialData),
          // @context.consignment.consolidation (Facet)
          consolidation,
          autoprint: getAutoprintInstrumentationContext({
            printConfig: supplementalData.autoprintConfig?.config,
            printClientDetails: supplementalData.autoprintConfig?.client,
            autoprintPreference: supplementalData.autoprintPreferences,
          }),
        },
        quotes: getQuotesContext(formData, supplementalData, quoteInteractions.value),
        // @context.new_labels_printed (Facet)
        new_labels_printed: supplementalData.labelUrl?.includes('newItemsOnly') || false,
      });

      FullStory.event('consignment updated', {
        consolidation,
        newLabelsPrinted: supplementalData.labelUrl?.includes('newItemsOnly') || false,
      });
    },
  );

  instrumentation.on(ConsignmentInstrumentation.ADDRESS_MODE_CHANGE, ({ isManualMode }) => {
    FullStory.event('address mode change', { mode: isManualMode ? 'estimate' : 'suggestion' });
  });

  instrumentation.on(
    ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_MISSED,
    ({
      type,
      suggestions,
      receiverPrevented,
    }: {
      type: MovementFlowType;
      suggestions: ConsignmentConsolidationSuggestion[];
      receiverPrevented: boolean;
    }) => {
      userMonitor.event('consignment consolidation opportunity missed', {
        consignment: {
          type,
        },
        suggested_consignment_ids: suggestions.map(suggestion => suggestion.id),
        // @context.receiver_prevented (facet)
        receiver_prevented: receiverPrevented,
      });
    },
  );

  instrumentation.on(
    ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_SUGGESTIONS_PRESENTED,
    ({
      type,
      estimateId,
      orderId,
      suggestions,
    }: {
      type: MovementFlowType;
      estimateId?: string;
      orderId?: string;
      suggestions: ConsignmentConsolidationSuggestion[];
    }) => {
      userMonitor.event('consignment consolidation suggestions presented', {
        consignment: {
          type,
          // @context.consignment.isConvertedEstimate
          isConvertedEstimate: !!estimateId,
          // @context.consignment.isConvertedOrder
          isConvertedOrder: !!orderId,
        },
        suggested_consignment_ids: suggestions.map(suggestion => suggestion.id),
      });
      FullStory.event('consignment consolidation suggestions presented', {
        consignmentType: type,
        suggestedConsignmentIds: suggestions.map(suggestion => suggestion.id),
      });
    },
  );

  instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_DETAILS_EXPANDED, ({ consignment }) => {
    const consignmentStatus = getConsignmentStatus({ consignment }).label;

    userMonitor.event('consignment details expanded', {
      consignment: {
        // @context.consignment.id
        id: consignment.id,
        // @context.consignment.status
        status: consignmentStatus,
        // @context.consignment.issues
        issues: Object.keys(consignment.issues || {}).length,
      },
    });

    FullStory.event(
      'consignment details expanded',
      {
        consignmentId: consignment.id,
        consignmentStatus,
        consignmentIssues: Object.keys(consignment.issues || {}).length,
      },
      {
        consignmentIssues: 'int',
      },
    );
  });

  instrumentation.on(
    ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_REJECTED,
    ({
      consignmentId,
      type,
      estimateId,
      orderId,
      suggestions,
    }: {
      consignmentId: string;
      type: MovementFlowType;
      estimateId?: string;
      orderId?: string;
      suggestions: ConsignmentConsolidationSuggestion[];
    }) => {
      userMonitor.event('consignment consolidation opportunity rejected', {
        consignment: {
          id: consignmentId,
          type,
          // @context.consignment.isConvertedEstimate
          isConvertedEstimate: !!estimateId,
          // @context.consignment.isConvertedOrder
          isConvertedOrder: !!orderId,
        },
        suggested_consignment_ids: suggestions.map(suggestion => suggestion.id),
      });
    },
  );

  instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_CONSOLIDATION_DISCARDED, () => {
    userMonitor.event('consignment consolidation discarded');
    FullStory.event('consignment consolidation discarded', {});
  });

  instrumentation.on(ConsignmentInstrumentation.CONSIGNMENT_AUTOPRINT_LABEL_COPIES_UPDATED, ({ labelCopies }) => {
    userMonitor.event('consignment autoprint label copies updated', {
      autoprint: {
        // @context.autoprint.labelCopies
        labelCopies,
      },
    });
    FullStory.event('consignment autoprint label copies updated', {});
  });

  instrumentation.on(
    ConsignmentInstrumentation.CONSIGNMENT_PRESELECTED_SERVICE_QUOTE_UPDATED,
    ({ consignmentId, quote }) => {
      userMonitor.event('consignment preselected service quote updated', {
        consignment: {
          id: consignmentId,
        },
        quoteId: quote.id,
        // @context.quoteNotSelectable
        quoteNotSelectable: !quote.selectable,
        // @context.quoteUnrated
        quoteUnrated: quote.selectable && !quote.recommended,
      });
      FullStory.event('consignment preselected service quote updated', {});
    },
  );

  instrumentation.on(ConsignmentInstrumentation.TODAYS_DISPATCH_DATE_UPDATED, (dispatchDate: Temporal.PlainDate) => {
    userMonitor.event("consignment outbound today's dispatch date saved", {
      daysFromToday: dispatchDate.since(plainDateNow()).days,
    });
  });
}
