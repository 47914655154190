import VueInstrumentation from './VueInstrumentation';

const instrumentation = new VueInstrumentation();

instrumentation.install = function install(app, options) {
  // VueRouter integration
  if (options.router) {
    this._router = options.router;

    // instrument each route change as a pageview
    this._router.afterEach(to => {
      const { path, params, query } = to;
      this.event(`pageview ${to.name}`, { path, params, query });
    });
  }

  // Vuex integration
  if (options.store) {
    this._store = options.store;

    // instrument each action as a span
    this._store.subscribeAction({
      before: async action => {
        action._span = this.span(action.type, { action, store: this._store });
      },
      after: async action => {
        if (!action._span) return;
        // Include the full store state in the action so meanin
        action._span.end({ action, store: this._store });
      },
    });
  }
};

export default instrumentation;
