import { AgreedService } from '@/shared/models';

import Quote, { QuotePrice } from './Quote';
import { WithRequired } from '@/shared/types/utility';

interface QuoteWithService extends Quote {
  agreedServiceModel: AgreedService;
}

export type QuoteWithServiceAndPrices = QuoteWithService & {
  price: WithRequired<QuotePrice, 'cost'>;
};

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
export type NonNullableField<T, K extends keyof T> = T & NonNullableFields<Pick<T, K>>;

/**
 * Quote with rank means pricing is available.
 * - cost information might be undefined if isMasked is true.
 */
export type QuoteWithServiceAndRank = QuoteWithService & {
  price: NonNullableField<QuotePrice, 'rank'>;
};

export const isQuoteWithPrices = (quote: Quote): quote is QuoteWithServiceAndPrices => !!quote.price.cost;
export const isQuoteRanked = (quote: Quote): quote is QuoteWithServiceAndRank => quote.price.rank !== null;

export default QuoteWithService;

const create = ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  createdAt,
  eta,
  quoteSetId,
  evaluation,
  selectable,
  recommended,
  agreedServiceModel,
  price,
}: QuoteWithService): QuoteWithService => ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  createdAt,
  eta,
  quoteSetId,
  evaluation,
  selectable,
  recommended,
  agreedServiceModel,
  price,
});

const createFromQuote = (quote: Quote, agreedService: AgreedService): QuoteWithService => {
  return create({
    ...quote,
    agreedServiceModel: agreedService,
  });
};

export const quoteWithServiceFactory = {
  create,
  createFromQuote,
};
