import { createDefaultState } from '@/app/modules/Auth/store/state';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const AuthStore = {
  namespaced: true,
  state: createDefaultState(),
  getters,
  mutations,
  actions,
};

export default AuthStore;
