import { asyncImport, withContextPrefix } from '@/shared/router/helpers';

const TrackRoutes = [
  ...withContextPrefix(
    {
      path: 'consignment',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: 'track',
        name: 'track.consignments',
        component: asyncImport(() => import('@/app/modules/ConsignmentTrack/views/ConsignmentTrack.vue')),
        meta: {
          title: 'Track consignments',
          requiresAuthentication: true,
          enableFullStory: true,
          context: {
            allowed: ['site', 'org'],
          },
        },
      },
    ],
  ),
];

export default TrackRoutes;
