import { sift } from 'radash';
import { reactive, Ref, ref } from 'vue';

import type { AgreedService, Consignment, ConsignmentSummary, MovementFlowType, Quote } from '@/shared/models';

import { consignmentService } from '@/app/common/services';

import { useOrgStorage } from '@/app/scopedStorage';
import useQuoteDetails from '@/app/modules/Consignment/behaviours/useQuoteDetails';

export const recentlyViewedConsignmentLimit = 4;

export interface SanitisedConsignmentSummary {
  id: string;
  type: MovementFlowType;
  consignmentNo: string;
  dispatchDate: PlainDateString;
  references: string[];
  issues: Consignment['issues'];
  site: {
    id: string;
  };
  carrier: {
    id?: string;
    name?: string;
  };
  _status?: 'loading' | 'hydrated' | 'failed';
}

const mapConsignmentToSanitisedConsignmentSummary = (
  consignment: Consignment,
  agreedService?: AgreedService,
): SanitisedConsignmentSummary => ({
  id: consignment.id,
  type: consignment.type,
  consignmentNo: consignment.consignmentNo as string,
  dispatchDate: consignment.dispatchDate,
  references: consignment.references,
  issues: consignment.issues,
  site: {
    id: consignment.siteId as string,
  },
  carrier: {
    id: agreedService?.carrier.id,
    name: agreedService?.carrier.name,
  },
});

const useRecentlyViewedConsignments = () => {
  const orgStorage = useOrgStorage();
  const { getAgreedServiceForQuote } = useQuoteDetails();

  const saveRecentlyViewedConsignments = (consignments: SanitisedConsignmentSummary[]): void => {
    orgStorage.setItem('recentlyViewedConsignments', consignments);
  };

  const loadRecentlyViewedConsignments = (): SanitisedConsignmentSummary[] => {
    const consignments =
      orgStorage.getItem<(SanitisedConsignmentSummary | undefined)[]>('recentlyViewedConsignments') || [];
    return sift(consignments);
  };

  const hydrateConsignments = async (
    consignments: SanitisedConsignmentSummary[],
    error: Ref<boolean>,
  ): Promise<void> => {
    const ids = consignments.map(c => c.id);
    let results: { consignments: ConsignmentSummary[] } | undefined;
    try {
      results = await consignmentService.getConsignments({ consignmentIds: ids });
    } catch (err) {
      logger.warn('Error fetching recently viewed consignments', err);
      error.value = true;
    }

    consignments.forEach((consignment, index) => {
      const updated = results?.consignments.find(c => c.id === consignment.id);
      if (updated) {
        Object.assign(consignments[index], updated);
        consignments[index]._status = 'hydrated';
      } else {
        consignments[index]._status = 'failed';
      }
    });
  };

  const getRecentlyViewedConsignments = () => {
    const recentlyViewedConsignments = loadRecentlyViewedConsignments();
    const consignments = reactive<SanitisedConsignmentSummary[]>(
      recentlyViewedConsignments.map(c => ({ ...c, _status: 'loading' })),
    );
    const error = ref(false);

    if (consignments.length) {
      hydrateConsignments(consignments, error).then(() => {
        // use .then() so the parent function can return early
        // 'failed' status from hydration means the consignment was deleted, or the user isn't authorized to view it.
        // remove these consignments from recently viewed so they doesn't appear in the view.
        const failedConsignments = consignments.filter(c => c._status === 'failed');
        if (failedConsignments.length) {
          // remove failed consignments from the visible list
          failedConsignments.forEach(failedCon => {
            consignments.splice(
              consignments.findIndex(consignment => failedCon.id === consignment.id),
              1,
            );
          });

          // re-save recently viewed consignments with deleted consignments omitted
          const nonFailedConsignments = recentlyViewedConsignments.filter(
            ({ id }) => !failedConsignments.find(failedCon => failedCon.id === id),
          );
          saveRecentlyViewedConsignments(nonFailedConsignments);
        }
      });
    }

    return { consignments, error };
  };

  const addRecentlyViewedConsignment = ({ consignment, quote }: { consignment: Consignment; quote?: Quote }): void => {
    const recentlyViewedConsignments: SanitisedConsignmentSummary[] = loadRecentlyViewedConsignments();

    // if this consignment is already in the list, remove it so it'll appear back at the top
    const existingIndex = recentlyViewedConsignments.findIndex(c => c.id === consignment.id);
    if (existingIndex > -1) {
      // remove from list
      recentlyViewedConsignments.splice(existingIndex, 1);
    }

    // add our consignment to the top of the list
    const agreedService = quote ? getAgreedServiceForQuote(quote) : undefined;
    recentlyViewedConsignments.unshift(mapConsignmentToSanitisedConsignmentSummary(consignment, agreedService));

    // save the first n items on our list
    saveRecentlyViewedConsignments(recentlyViewedConsignments.slice(0, recentlyViewedConsignmentLimit));
  };

  return {
    addRecentlyViewedConsignment,
    getRecentlyViewedConsignments,
  };
};

export default useRecentlyViewedConsignments;
