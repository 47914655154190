<template>
  <section class="section auth-wrapper">
    <div class="container">
      <div class="panel is-primary" :class="{ 'has-title': !!title }">
        <div v-if="title" class="panel-heading">{{ title }}</div>
        <div class="panel-block is-active">
          <div class="content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'AuthWrapper',
    props: {
      title: { type: String, default: undefined },
    },
  };
</script>

<style lang="scss" scoped>
  .auth-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 100%;
    min-width: 100%;
  }

  .panel {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: var(--radius-m);
  }

  .panel-heading {
    padding: var(--space-xs) var(--space-m);
    text-align: center;
  }

  .panel-block {
    padding: var(--space-xl);
    background-color: $chrome-30;

    .panel:not(.has-title) & {
      border-radius: var(--radius-m);
    }

    :deep(.content) {
      width: 100%;
    }
  }
</style>
