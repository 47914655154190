<script setup lang="ts">
  import SvgIcon from '@/shared/icons/SvgIcon.vue';

  import ArrowRightIcon from '@/shared/icons/svgs/arrow-right.svg';

  import ErrorLayout from '@/app/layout/views/ErrorLayout.vue';

  import {
    consignmentEditRoute,
    consignmentsReadyToManifestRoute,
    consignmentViewRoute,
  } from '@/app/modules/Consignment/helpers/links';
  import { computed } from 'vue';
  import { RouteLocationRaw } from 'vue-router';
  import { useSiteId } from '@/app/context';

  const { resourceType, resourceId } = defineProps<{
    resourceType: 'consignment';
    resourceId: string;
  }>();

  const siteId = useSiteId();

  interface ErrorAction {
    label: string;
    link: RouteLocationRaw;
  }

  const errorActions = computed<ErrorAction[]>(() => {
    if (resourceType === 'consignment') {
      return [
        {
          label: 'View the consignment',
          link: consignmentViewRoute({
            id: resourceId,
            siteId: siteId.value,
          }),
        },
        {
          label: 'Edit the consignment',
          link: consignmentEditRoute({
            id: resourceId,
            siteId: siteId.value,
          }),
        },
        {
          label: 'Ready-to-manifest consignments',
          link: consignmentsReadyToManifestRoute({}),
        },
      ];
    }
    return [];
  });
</script>

<template>
  <ErrorLayout :title="`There is a conflict with the ${resourceType} information.`">
    <template #figure>
      <img src="@/assets/404.svg?url" alt="404" />
    </template>
    <template #default>
      <p>It looks like this {{ resourceType }} was updated by someone else while you were editing it.</p>
      <p>Here are some helpful links to get you back on track:</p>
      <ul>
        <li>
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.go(-1)">Go back</a>
        </li>
        <li v-for="action in errorActions" :key="action.label">
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <RouterLink :to="action.link">{{ action.label }}</RouterLink>
        </li>
      </ul>
    </template>
  </ErrorLayout>
</template>

<style lang="scss" scoped></style>
