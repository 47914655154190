import { type FunctionPlugin, ref } from 'vue';
import { NavigationFailure, type RouteLocationNormalizedGeneric, type Router } from 'vue-router';
import instrumentation from '@/shared/instrumentation';
import { Prettify } from '@/shared/types/utility';

interface RemoteVersionInfo {
  sha: string;
  etag?: string;
  lastModified?: string;
}

interface VersionMonitorInstallOptions {
  initialSHA: string;
  router: Router;
  enabled?: boolean;
  interval?: number;
}

export const newVersionAvailable = ref(false);

export const NEW_VERSION_DETECTED_EVENT = 'new version detected';
export const VERSION_UPGRADE_PREVENTED_EVENT = 'version upgrade prevented';

export const PREVENT_VERSION_RELOAD = 'preventVersionReload';

// helper function for preventing force reload when window.history.state is populated
export const protectState = <T extends object>(state: T) =>
  ({
    ...state,
    [PREVENT_VERSION_RELOAD]: true,
  }) as Prettify<T & { [PREVENT_VERSION_RELOAD]: boolean }>;

export const fetchRemoteVersion = async (): Promise<RemoteVersionInfo> => {
  let manifest;
  let etag;
  let lastModified;

  try {
    manifest = await fetch(`${window.location.origin}/manifest.json?ts=${+new Date()}`).then(response => {
      etag = response.headers.get('etag');
      lastModified = response.headers.get('last-modified');

      return response.json();
    });

    if (!manifest || !manifest.version) throw new Error('error parsing manifest.json');
  } catch (error) {
    logger.error('version detection failed to fetch and parse remote manifest.json', error);
  }

  return {
    sha: manifest.version,
    etag,
    lastModified,
  };
};

export const checkVersion = async (
  client_version: string,
  intervalID?: ReturnType<typeof setInterval>,
): Promise<void> => {
  try {
    const { sha: remoteVersion, etag, lastModified } = await fetchRemoteVersion();
    newVersionAvailable.value = remoteVersion !== client_version;

    if (newVersionAvailable.value) {
      if (intervalID) clearInterval(intervalID);

      logger.info(NEW_VERSION_DETECTED_EVENT, {
        new_version: remoteVersion,
        etag,
        lastModified,
      });

      instrumentation.event(NEW_VERSION_DETECTED_EVENT, {
        new_version: remoteVersion,
        etag,
        lastModified,
      });
    }
  } catch (error) {
    // log and fail silently so the user isn't impacted
    logger.error('VersionMonitor detection failed due to error', { error });
  }
};

// if a new version has been detected, reload the SPA to get the latest version
export const reloadOnNewVersion = (
  to?: RouteLocationNormalizedGeneric,
  from?: RouteLocationNormalizedGeneric,
  navigationFailure?: NavigationFailure | void,
): boolean => {
  if (navigationFailure) return false;

  if (
    newVersionAvailable.value &&
    !window.history.state?.[PREVENT_VERSION_RELOAD] &&
    !to?.meta?.[PREVENT_VERSION_RELOAD]
  ) {
    logger.info('navigation after new version detected: force reload');

    window.location.reload();

    return true;
  } else if (newVersionAvailable.value) {
    instrumentation.event(VERSION_UPGRADE_PREVENTED_EVENT, {
      routeBlocked: !!to?.meta?.[PREVENT_VERSION_RELOAD],
      historyState: !!window.history.state?.[PREVENT_VERSION_RELOAD],
    });
  }

  return false;
};

export const VersionMonitor: FunctionPlugin = (app, options: VersionMonitorInstallOptions) => {
  const {
    enabled = true,
    interval = 10 * 60 * 1000, // 10min
    initialSHA,
    router,
  } = options;
  let intervalID: ReturnType<typeof setInterval>;

  try {
    if (enabled) {
      intervalID = setInterval(() => {
        checkVersion(initialSHA, intervalID);
      }, interval);

      if (router) router.afterEach(reloadOnNewVersion);
    }
  } catch (error) {
    // log and fail silently so the user isn't impacted
    logger.error('VersionMonitor failed to start', { error });
  }
};
