import { computed, MaybeRef, Ref, toValue } from 'vue';
import { useStore } from 'vuex';

import OrgStore from '@/app/modules/Org/store/types';

import type { Context, ContextType } from './types';

export interface UseContextSourceConfig {
  contextType: Ref<ContextType | undefined>;
  contextId: Ref<string | undefined>;
}

export const useContextSource = ({ contextType, contextId }: UseContextSourceConfig) => {
  const store = useStore();
  const getSite = store.getters[OrgStore.getters.site];
  const getOrg = store.getters[OrgStore.getters.organisation];
  const allOrganisations = computed(() => store.getters[OrgStore.getters.organisations]);

  const userCanAccessContext = (
    checkContextType: MaybeRef<ContextType | undefined>,
    checkContextId: MaybeRef<string | undefined>,
  ) => {
    // if the site is in the list of sites from org, the user has access
    if (toValue(checkContextType) === 'site' && !!getSite(toValue(checkContextId))) {
      return true;
    }
    // the user has access to at least one site with this org id
    if (toValue(checkContextType) === 'org' && !!getOrg(toValue(checkContextId))) {
      return true;
    }

    // if contextType was invalid, or the user did not have the requested site/org in
    // their org payload, they do not have access
    return false;
  };

  const contextFromTypeAndId = computed<Context>(() => {
    const emptyContext = { site: undefined, org: undefined };

    if (!contextType.value || !contextId.value) return emptyContext; // no context

    if (contextType.value === 'site') {
      const site = getSite(contextId.value);

      // invalid state
      if (!site) return emptyContext;

      return {
        site,
        org: getOrg(site.organisation.id),
      };
    }

    const org = getOrg(contextId.value);

    // invalid state
    if (!org) return emptyContext;

    return {
      site: undefined,
      org,
    };
  });

  return {
    contextFromTypeAndId,
    userCanAccessContext,
    allOrganisations,
  };
};
