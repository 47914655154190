<template>
  <template v-if="loading">
    <CarrierPickupStatusCard :status-icon="StatusIconSubmitting" title="Loading pickup details" />
  </template>
  <div v-else-if="pickup" class="card mt-5 mb-5">
    <div class="card-content">
      <div v-if="statusIcon" class="status-icon">
        <component :is="statusIcon" />
      </div>

      <h1 class="is-text-h1 has-text-centered">
        <slot name="title"></slot>
      </h1>
      <br />

      <div class="summary">
        <div class="summary-item">
          <span class="summary-label">From</span>
          <span v-sensitive.always
            ><strong>{{ pickup.sender.name }}</strong></span
          >
        </div>
        <div class="summary-item">
          <span class="summary-label">Carrier account</span>
          <span v-sensitive.always>{{ pickup.carrierChargeAccount }}</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Carrier | Service</span>
          <span v-sensitive.always>{{ pickup.carrierName }} | {{ pickup.serviceName }}</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Pickup date</span>
          <span v-sensitive.always>{{ temporalPlainDateToLocalString(pickup.pickupDate) }}</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Pickup window</span>
          <span v-sensitive.always>{{ formatTimeInterval(pickup.pickupWindow) }}</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Total volume</span>
          <span>{{ mm2m3(pickup.totalVolume, 3) }} m³</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Total weight</span>
          <span>{{ gr2kg(pickup.totalWeight, 2) }} kg</span>
        </div>
        <div class="summary-item">
          <span class="summary-label">Total items</span>
          <span>{{ pickup.totalItems }}</span>
        </div>
      </div>

      <hr />

      <slot></slot>
    </div>
    <div class="card-footer">
      <CarrierPickupChangeResponseFooter :pickup="pickup" />
    </div>
  </div>
</template>

<script setup>
  import { h } from 'vue';

  import { formatTimeInterval, temporalPlainDateToLocalString } from '@/shared/DateTime/formatters';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import IconSubmitting from '@/shared/icons/svgs/submitting.svg';

  import CarrierPickupStatusCard from '@/app/carrierPickup/components/CarrierPickupStatusCard.vue';

  import { gr2kg, mm2m3 } from '@/app/modules/Consignment/helpers';

  import CarrierPickupChangeResponseFooter from './CarrierPickupChangeResponseFooter.vue';

  defineProps({
    loading: { required: false, type: Boolean, default: false },
    pickup: { required: false, type: Object, default: null },
    statusIcon: { required: false, default: undefined, type: Object },
  });

  const StatusIconSubmitting = h(SvgIcon, { svg: IconSubmitting, style: 'color: var(--info-100)' });
</script>

<style lang="scss" scoped>
  .card {
    width: 400px;
  }

  .status-icon:deep(.svg-icon) {
    width: 100%;
    height: 66px;
  }

  .summary {
    display: grid;
    grid-template-columns: 1fr 2fr;

    .summary-item {
      display: contents;
    }
  }

  .summary-label {
    @include H3-default;
    display: inline-block;
    width: 140px;
  }

  .card-footer {
    color: var(--copy-70);
  }
</style>
