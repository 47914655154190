<template>
  <div class="list-box-separator"></div>
</template>

<script>
  export default {
    name: 'ListBoxSeparator',
  };
</script>

<style scoped lang="scss">
  .list-box-separator {
    margin: var(--space-xs) 10px;
    border-bottom: 1px solid var(--chrome-100);

    &:last-child {
      display: none;
    }
  }
</style>
