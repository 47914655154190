<template>
  <div class="actions-bar">
    <slot name="messages"></slot>
    <div class="actions-bar-actions hidden-if-empty">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ActionsBar',
    props: {
      justifyContent: { default: 'flex-end', type: String },
    },
  };
</script>

<style scoped lang="scss">
  .actions-bar {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
  }
  .actions-bar-actions {
    display: flex;
    justify-content: v-bind(justifyContent);
    align-items: center;
    gap: var(--space-xs);

    > :deep(button:only-child) {
      margin-left: auto;
    }
  }
</style>
