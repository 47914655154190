import { asyncImport, withContextPrefix } from '@/shared/router/helpers';

const ShippingItemPresetRoutes = [
  ...withContextPrefix(
    {
      path: 'shipping-item-presets',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: '',
        name: 'shipping-item-presets.manage',
        component: asyncImport(() => import('@/app/modules/ShippingItemPreset/views/ManageShippingItemPresets.vue')),
        meta: {
          title: 'Shipping item presets',
          enableFullStory: true,
          context: {
            allowed: ['site'],
          },
        },
      },
    ],
  ),
];

export default ShippingItemPresetRoutes;
