import { defineComponent, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import { useDefaultContext, useDefaultLandingPage } from '@/app/modules/Site/behaviours/defaultPage';

const DefaultSiteRedirect = defineComponent({
  setup() {
    const router = useRouter();
    const { getDefaultContext } = useDefaultContext();
    const { getDefaultLandingPage, setDefaultLandingPage } = useDefaultLandingPage();

    onBeforeMount(async () => {
      const defaultContext = getDefaultContext();
      let defaultPage = getDefaultLandingPage();

      // Temporal resolution of deprecated route names
      const deprecatedRouteNames: Record<string, string> = {
        'consignment.list': 'consignment.readyToManifest',
        'pickup.list': 'pickup.manage',
        'manifest.list': 'manifest.manifest',
      };
      if (deprecatedRouteNames[defaultPage]) {
        // update default page with new route name
        setDefaultLandingPage(deprecatedRouteNames[defaultPage]);
        // avoid using the deprecated route
        defaultPage = deprecatedRouteNames[defaultPage];
      }

      if (!router.hasRoute(defaultPage)) {
        defaultPage = 'site';
      }

      router.push({
        name: defaultPage,
        params: {
          contextId: defaultContext.id,
          contextType: defaultContext.type,
        },
      });
    });

    return () => undefined;
  },
});

export default DefaultSiteRedirect;
