import { createTypeHelpers } from '@/app/store/store-helpers';

import { name } from '../module';

/**
 * Explicitly named getters, mutations and actions. Marked internal because
 * these will be used for definitions in the store, but most external code
 * will access these through the namespace, ie:
 *   '${MODULE_NAME}/CURRENT_ORGANISATION'
 */
const InternalTypes = {
  getters: {
    redirectPath: 'redirectPath',
    user: 'user',
    getUnconfirmedUser: 'getUnconfirmedUser',
    getResetPasswordUsername: 'getResetPasswordUsername',
    isUserMissingConfirmation: 'isUserMissingConfirmation',
    isVerificationCodeSent: 'isVerificationCodeSent',
    getVerificationCodeDetails: 'getVerificationCodeDetails',
    isPasswordResetComplete: 'isPasswordResetComplete',

    userRequiresMFAVerification: 'userRequiresMFAVerification',
  },

  mutations: {
    SET_USER: 'SET_USER',
    CLEAR_USER: 'CLEAR_USER',
    SET_REDIRECT: 'SET_REDIRECT',
    CLEAR_REDIRECT: 'CLEAR_REDIRECT',
    SET_UNCONFIRMED_USER: 'SET_UNCONFIRMED_USER',
    CLEAR_UNCONFIRMED_USER: 'CLEAR_UNCONFIRMED_USER',
    SET_RESET_PASSWORD_USERNAME: 'SET_RESET_PASSWORD_USERNAME',
    CLEAR_RESET_PASSWORD_USERNAME: 'CLEAR_RESET_PASSWORD_USERNAME',
    SET_VERIFICATION_CODE_DETAILS: 'SET_VERIFICATION_CODE_DETAILS',
    FORGOT_PASSWORD_COMPLETE: 'FORGOT_PASSWORD_COMPLETE',
    RESET_FORGOT_PASSWORD: 'RESET_FORGOT_PASSWORD',

    SET_MFA_VERIFICATION_REQUIRED: 'SET_MFA_VERIFICATION_REQUIRED',
  },

  actions: {
    login: 'login',
    logout: 'logout',
    completeNewPassword: 'completeNewPassword',
    confirmSession: 'confirmSession',
    checkExistingSession: 'checkExistingSession',
    saveRedirect: 'saveRedirect',
    clearRedirect: 'clearRedirect',
    clearAll: 'clearAll',

    forgotPassword: 'forgotPassword',
    forgotPasswordComplete: 'forgotPasswordComplete',
    resetForgotPassword: 'resetForgotPassword',

    verifyMFATOTP: 'verifyMFATOTP',
  },
};

/**
 * Derive namespaced versions of each constant that can be used in views and
 * components directly, ie:
 *   this.$store.dispatch(Org.actions.fetchUser)
 *     which is the same as:
 *   this.$store.dispatch('org/fetchUser')
 *
 * @typedef {InternalTypes}
 */
const ModuleTypes = createTypeHelpers(name, InternalTypes);

export { ModuleTypes as default, InternalTypes };
