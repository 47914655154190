<template>
  <div data-do-not-close="true" class="list-box-search">
    <ControlSearch
      ref="searchRef"
      v-model="searchVModel"
      class="list-box-search-control is-interactive do-not-close"
      :sensitive="sensitive"
    />
  </div>
</template>

<script setup>
  import { useVModel } from '@vueuse/core';
  import { ref } from 'vue';

  import ControlSearch from '@/shared/components/controls/ControlSearch.vue';

  const emit = defineEmits(['update:modelValue']);
  const props = defineProps({
    modelValue: {
      type: String,
      default: '',
    },
    sensitive: {
      type: Boolean,
      default: false,
    },
  });

  const searchRef = ref(null);

  const searchVModel = useVModel(props, 'modelValue', emit);

  defineExpose({
    focus: () => {
      searchRef.value.focus();
    },
  });
</script>

<style scoped lang="scss">
  .list-box-search {
    position: sticky;
    top: 0;
    transform: translateY(-8px); // position: sticky doesn't take padding into account, this fixes that
    background-color: #fff;
    padding: var(--space-m) var(--space-m) 0;
    z-index: 1; // overlap other items when sticky
  }

  :deep(.input) {
    height: 32px;
  }
  :deep(.control.has-icon-left .icon) {
    height: 32px;
  }
</style>
