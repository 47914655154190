import ApiClient, { ApiClientError, ApiClientRequestConfig } from '@/shared/services/api-client';
import { Rcsp } from '@/shared/models/ReceiverCarrierSelectionPolicy/Rcsp';
import { RcspFormData } from '@/shared/models/ReceiverCarrierSelectionPolicy/RcspFormData';
import {
  mapConflictAsValidationError,
  mapRcspFormDataToCreateRequest,
  mapRcspFormDataToUpdateRequest,
  mapRcspSearchParams,
  mapRcspSearchResponse,
} from '@/shared/services/org/mappers/senderPoliciesMappers';
import { RcspGetResponse, RcspSearchParams, RcspSearchResponse } from '@/shared/services/org/types/RcspClient';

export default class SenderPoliciesClient {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  public async searchRcsp(params: RcspSearchParams, config?: ApiClientRequestConfig) {
    const apiParams = mapRcspSearchParams(params);

    const { data } = await this.apiClient.query<RcspSearchResponse>('/sender-policies/receiver-carrier-selection', {
      ...config,
      params: apiParams,
    });

    return mapRcspSearchResponse(data);
  }

  public async getRcsp(id: UUID, config?: ApiClientRequestConfig): Promise<Rcsp> {
    const { data } = await this.apiClient.get<RcspGetResponse>(
      `/sender-policies/receiver-carrier-selection/${id}`,
      config,
    );
    return data.data;
  }

  public async createRcsp(formData: RcspFormData, siteId: UUID) {
    try {
      const response = await this.apiClient.post(
        '/sender-policies/receiver-carrier-selection',
        mapRcspFormDataToCreateRequest(formData, siteId),
      );

      if (response.status !== 201) {
        logger.warn('POST /sender-policies/receiver-carrier-selection resulted in non-201 status');
      }
    } catch (error) {
      if (error instanceof ApiClientError) {
        throw mapConflictAsValidationError(error);
        // https://dev.geppetto.services/api/org/sender-policies/receiver-carrier-selection
        //         http://localhost:8080/api/org/sender-policies/receiver-carrier-selection?filter[siteId]=6c39baa5-1160-544c-b9b7-92754b9a8484&filter[agreedServiceId]=all&page[limit]=30&page[offset]=0&sort=-addressBookEntryName
      }
      throw error;
    }
  }

  public async updateRcsp(id: UUID, formData: RcspFormData) {
    try {
      const response = await this.apiClient.put(
        `/sender-policies/receiver-carrier-selection/${id}`,
        mapRcspFormDataToUpdateRequest(formData),
      );

      if (response.status !== 201) {
        logger.warn('POST /sender-policies/receiver-carrier-selection resulted in non-201 status');
      }
    } catch (error) {
      if (error instanceof ApiClientError) {
        throw mapConflictAsValidationError(error);
      }
      throw error;
    }
  }

  public async activateRcsp(id: UUID) {
    const response = await this.apiClient.post(`/sender-policies/receiver-carrier-selection/${id}/activate`, {});
    if (response.status !== 204) {
      logger.warn('POST /sender-policies/receiver-carrier-selection/:id/activate resulted in non-204 status', { id });
      return false;
    }
    return true;
  }

  public async deactivateRcsp(id: UUID) {
    const response = await this.apiClient.post(`/sender-policies/receiver-carrier-selection/${id}/deactivate`, {});
    if (response.status !== 204) {
      logger.warn('POST /sender-policies/receiver-carrier-selection/:id/deactivate resulted in non-204 status', { id });
      return false;
    }
    return true;
  }

  public async deleteRcsp(id: UUID) {
    const response = await this.apiClient.delete(`/sender-policies/receiver-carrier-selection/${id}`);

    if (response.status !== 204) {
      logger.warn('DELETE /sender-policies/receiver-carrier-selection/:id resulted in non-204 status', { id });
    }
    // if it doesn't throw, success!
  }
}
