import { NEW_VERSION_DETECTED_EVENT, VERSION_UPGRADE_PREVENTED_EVENT } from '@/shared/plugins/VersionMonitor';

const appInstrumentationTypes = {
  ADVANCED_LISTING_FILTER_OPEN: 'advanced listing filter open',
  CONTEXT_CHANGED: 'context changed',

  BROWSER_CHECK_SEEN: 'browser check seen',
  BROWSER_CHECK_DISMISSED: 'browser check dismissed',

  USER_GUIDE_OPENED: 'user guide opened',
  USER_PREFERENCES_OPENED: 'user preferences opened',
  USER_PREFERENCES_SITE_DEFAULT_SET: 'site default set',
  USER_PREFERENCES_PAGE_DEFAULT_SET: 'page default set',
  USER_PREFERENCES_PAGE_DEFAULT_SIZE_SET: 'page size default set',

  TRACKED_WINDOW_BLOCKED: 'tracked window blocked',

  BACKEND_VALIDATIONS: 'backend validations',

  NEW_VERSION_DETECTED: NEW_VERSION_DETECTED_EVENT,
  VERSION_UPGRADE_PREVENTED: VERSION_UPGRADE_PREVENTED_EVENT,
} as const;

export default appInstrumentationTypes;
