import merge from 'lodash/merge';
import { unref } from 'vue';

import config, { auth as authConfig } from '@/config';
import { modalManager } from '@/shared/modals/modalManager';
import { notify } from '@/shared/notify';

import { forceNavigation } from '@/shared/router/forceNavigation';
import App from '@/app/store/types';
import Auth from '@/app/modules/Auth/store/types';

import Org from '@/app/modules/Org/store/types';
import SiteSelectModal from '@/app/modules/Site/components/SiteSelectModal.vue';

const authCheckInterval = (authConfig.checkInterval || 60) * 1000;

export const authenticationGuardFactory = (store, router) => {
  let authCheckTimer;

  const authCheckRedirect = async (currentRoute, navigate) => {
    // Ensure user session data in the store is current
    if (!(await store.dispatch(App.actions.confirmSession))) {
      logger.info('[AuthGuard] no valid user token, redirecting to login screen');

      await store.dispatch(App.actions.logout);
      await store.dispatch(Auth.actions.saveRedirect, currentRoute.fullPath);
      navigate(forceNavigation({ name: 'auth.login' }));
      return true;
    }
    return false;
  };

  return async (to, from, next) => {
    const isAuthenticatedRoute = [...to.matched].reverse().find(route => route.meta.requiresAuthentication);

    if (isAuthenticatedRoute) {
      // If the user isn't authenticated for this route, redirect and return early
      if (await authCheckRedirect(to, next)) return;

      // while the user is in an authenticated route, keep checking their access
      if (!authCheckTimer) {
        authCheckTimer = setInterval(async () => {
          await authCheckRedirect(unref(router.currentRoute), router.push);
        }, authCheckInterval);
      }
    } else {
      // When the user leaves authenticated routes, we don't need to keep
      // auth status up to date
      clearInterval(authCheckTimer);
      authCheckTimer = null;
    }

    next();
  };
};

const getContextMeta = matchedRoute => {
  const contextMeta = matchedRoute.matched?.reduce((p, c) => {
    merge(p, c.meta?.context || {});
    return p;
  }, {});
  return contextMeta || {};
};

const promptUserToSelectSite = async (store, to, from) => {
  // look for a meta property named 'selectSiteFor' in the matched route tree
  const contextMeta = getContextMeta(to);
  const { selectSiteFor } = contextMeta;

  const toOrgId = to.params.contextId;

  // get a list of possible sites to choose from
  const sites = Object.values(store.getters[Org.getters.sites]).filter(s => s.organisation.id === toOrgId);

  // open a modal prompting the user to select a site
  const siteId = await new Promise(resolve => {
    modalManager.open(SiteSelectModal, { 'onSelect:site': resolve, selectSiteFor, sites });
  });

  if (siteId) {
    return {
      ...to,
      params: {
        ...to.params,
        contextType: 'site',
        contextId: siteId,
      },
      query: {
        ...to.query,
        contextSwitch: true, // this prevents the user being alerted their site is changing.
      },
    };
  }

  // user cancelled the operation, so..

  // we're attempting to navigate here from another sender-ui page, prevent that action.
  if (from?.matched?.length) return false;

  // we pasted this URL or opened it in a new tab, go home.
  return { name: 'app.home' };
};

const alertUserAboutSiteChange = async (store, to) => {
  // look for a meta property named 'actionDescription' in the matched route tree
  const contextMeta = getContextMeta(to);
  const { actionDescription } = contextMeta;

  // get the name of the site the user is about to switch to
  const site = store.getters[Org.getters.site](to.params.contextId);
  if (!site) throw new Error('Unable to fetch site name');

  const message = actionDescription
    ? `By ${actionDescription} your site will change to ${site.name}`
    : `You are about to change site to ${site.name}`;
  const choice = await notify.dialog({
    type: notify.types.DEFAULT,
    title: 'Change site?',
    message,
    confirmButton: 'Yes, change site',
    cancelButton: 'Cancel',
  });

  const success = choice === notify.actions.CONFIRM;

  if (success) {
    store.dispatch(App.actions.siteChanged, true);
  }

  return success;
};

export const contextGuardFactory = store => {
  let hasContextSwitched = false;

  return async (to, from) => {
    // if we're moving somewhere without a context, that's fine
    if (!to.params?.contextType) return true;

    const contextMeta = getContextMeta(to);
    const allowedContextTypes = contextMeta.allowed || [];

    // if we're moving to an Org context
    if (to.params.contextType === 'org') {
      // this view allows an org context, carry on.
      if (allowedContextTypes.includes('org')) return true;

      // if we're already on this view, and we've just selected 'sites overview', take the user
      // to the default route. (currently consignment list)
      if (to.name === from?.name) {
        return {
          name: config.defaultRoute,
          params: {
            contextType: to.params.contextType,
            contextId: to.params.contextId,
          },
        };
      }

      // ask the user for a site
      return promptUserToSelectSite(store, to, from);
    }

    // todo: what do we do if the context.meta.allowed does not contain ['site']?
    // Currently unneeded / unnacounted for in spec.
    // if (!allowedContextTypes.includes('site')) return false;

    // this is our first navigation event, or we've come from a non-contextual page, carry on.
    if (!from?.params?.contextType) return true;

    // Check if the context has actually changed
    const fromContext = `${from.params.contextType}-${from.params.contextId}`;
    const toContext = `${to.params.contextType}-${to.params.contextId}`;
    if (fromContext === toContext) return true;

    // Debug log
    logger.debug(`Context has changed from ${fromContext} to ${toContext}`);

    // if we have the contextSwitch query parameter, this was on purpose, so we don't need to let the user know
    const { contextSwitch, ...toQuery } = to.query || {};
    if (contextSwitch) {
      logger.debug('Context change was intentional, cleaning query string');
      hasContextSwitched = true;
      return {
        ...to,
        query: toQuery,
      };
    }

    // This was a redirect generated by the contextSwitch logic above. carry on.
    if (hasContextSwitched) {
      hasContextSwitched = false;
      return true;
    }

    // we need to warn the user they're about to switch context
    return alertUserAboutSiteChange(store, to);
  };
};
