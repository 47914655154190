import { asyncImport, withContextPrefix } from '@/shared/router/helpers';

const ManifestRoutes = [
  ...withContextPrefix(
    {
      path: 'manifest',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: 'create',
        name: 'manifest.create',
        component: asyncImport(() => import('@/app/modules/Manifest/views/CreateManifests.vue')),
        meta: {
          title: 'Create manifests',
          enableFullStory: true,
          context: {
            allowed: ['site'],
            selectSiteFor: 'manifest',
          },
        },
      },
      {
        path: 'manage',
        name: 'manifest.manage',
        component: asyncImport(() => import('@/app/modules/Manifest/views/ManifestList.vue')),
        meta: {
          title: 'Manage manifests',
          enableFullStory: true,
          context: {
            allowed: ['site', 'org'],
          },
        },
      },
      // Temporal redirection, remove when we confirm this url is no longer used
      {
        path: 'list',
        redirect: { name: 'manifest.manage' },
      },

      // {
      //   path: ':manifestId',
      //   name: 'manifest.view',
      //   component: ViewConsignment,
      //   meta: {
      //     title: 'View manifest',
      //   },
      // },

      {
        path: '',
        redirect: { name: 'manifest.create' },
      },
    ],
  ),
];

export default ManifestRoutes;
