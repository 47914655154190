import debounce from 'lodash/debounce';

import { session } from '@/config';
import instrumentation from '@/shared/instrumentation';
import { modalManager } from '@/shared/modals/modalManager';

import BlockedWindowModal from '@/shared/components/BlockedWindowModal.vue';
// eslint-disable-next-line no-restricted-imports
import appInstrumentationTypes from '@/app/instrumentation/types';

export function handleBlockedWindow(route) {
  if (session.isSynthetic || session.isCypress) return;

  modalManager.open(BlockedWindowModal);
  logger.warn('Window blocked from opening:', { path: route.href });
  instrumentation.event(appInstrumentationTypes.TRACKED_WINDOW_BLOCKED);
}

export default debounce(handleBlockedWindow, 200);
