import ConsignmentResultSetStore, {
  types as ConsignmentResultSetStoreTypes,
} from '@/app/store/modules/ConsignmentResultSetStore';

import { name as moduleName } from '../module';

const types = ConsignmentResultSetStoreTypes;

/**
 * Consignment store definition
 */
const GlobalSearchStore = {
  ...ConsignmentResultSetStore,
};

const namespace = moduleName;

export { GlobalSearchStore as default, types, namespace };
