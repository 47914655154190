import { computed } from 'vue';

import appFeatures from '@/app/config/appFeatures';
import { useFeature } from '@/app/config/features';

const useDGFeature = () => {
  const feature = useFeature();

  // general 'permanent' permissions
  const isDGFeatureOn = feature.enabled(appFeatures.dangerousGoods);
  const wasDGFeatureOn = computed(() => feature.config(appFeatures.dangerousGoods).value.hasTransacted);
  const isUserDGAuthorised = feature.enabled(appFeatures.userCanTransactWithDangerousGoods);

  // specific derived permissions
  const canViewConsignmentsWithDG = computed(() => isDGFeatureOn.value || wasDGFeatureOn.value);
  const canViewPickupsWithDG = computed(() => isDGFeatureOn.value || wasDGFeatureOn.value);
  const canViewEstimatesWithDG = computed(() => isDGFeatureOn.value || wasDGFeatureOn.value);
  const canCreateEstimatesWithDG = computed(() => isDGFeatureOn.value); // just site permissions, any user can create estimates.
  const canManifestConsignmentsWithDG = computed(() => true);

  // in development features
  const isDGNotifiableFeatureOn = feature.enabled(appFeatures.dangerousGoodsNotifiable);

  return {
    isDGFeatureOn,
    wasDGFeatureOn,
    isUserDGAuthorised,

    canViewConsignmentsWithDG,
    canViewPickupsWithDG,
    canViewEstimatesWithDG,
    canCreateEstimatesWithDG,
    canManifestConsignmentsWithDG,

    isDGNotifiableFeatureOn,
  };
};

export default useDGFeature;
