<template>
  <div class="list-box-title">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'ListBoxTitle',
  };
</script>

<style scoped lang="scss">
  .list-box-title {
    @include Subhead2-default;
    color: var(--copy-70);
    padding: var(--space-xs) var(--space-m) var(--space-xxxs) var(--space-m);
  }
</style>
