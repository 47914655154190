import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import userMonitor from '@/app/instrumentation/userMonitor';

import { getConsignmentStatus } from '../../Consignment/helpers';

import globalSearchInstrumentationTypes from './types';
import { ConsignmentSummary } from '@/shared/models';

instrumentation.on(
  globalSearchInstrumentationTypes.CONSIGNMENT_OPENED,
  ({
    consignmentSummary,
    isRecentlyViewed,
    searchTerm,
  }: {
    consignmentSummary: ConsignmentSummary;
    isRecentlyViewed: boolean;
    searchTerm: string;
  }) => {
    const consignmentStatus = getConsignmentStatus({ consignment: consignmentSummary }).label;

    let searchType = 'other';
    if (isRecentlyViewed) {
      searchType = 'recent';
    } else if (consignmentSummary.consignmentNo === searchTerm) {
      searchType = 'consignmentNoExact';
    } else if (consignmentSummary.consignmentNo.includes(searchTerm)) {
      searchType = 'consignmentNo';
    }

    userMonitor.event('consignment opened from search', {
      consignment: {
        // @context.consignment.id
        id: consignmentSummary.id,
        // @context.consignment.status
        status: consignmentStatus,
      },
      globalSearch: {
        // @context.globalSearch.searchType
        searchType,
      },
    });

    FullStory.event('consignment opened from search', {
      consignmentId: consignmentSummary.id,
      consignmentStatus,
      searchType,
    });
  },
);
