import { operations } from '@/shared/services/schema/geppetto-org/receivercarrierselectionpolicies.schema';
import { Rcsp } from '@/shared/models/ReceiverCarrierSelectionPolicy/Rcsp';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import mapPaginatedResponse from '@/shared/services/api-client/helpers/mapPaginatedResponse';
import { RcspFormData } from '@/shared/models/ReceiverCarrierSelectionPolicy/RcspFormData';
import MappingError from '@/shared/services/errors/MappingError';
import { ApiClientError, ValidationError } from '@/shared/services/api-client';
import { RcspSearchParams, RcspSearchResponse } from '@/shared/services/org/types/RcspClient';

export function mapRcspSearchParams(
  params: RcspSearchParams,
): operations['searchReceiverCarrierSelectionPolicies']['parameters']['query'] {
  if (!params.filters?.siteId) throw new MappingError('siteId is required');

  return {
    'filter[siteId]': params.filters.siteId,
    'filter[agreedServiceId]':
      params.filters?.agreedServiceId && !params.filters?.agreedServiceId.includes('all')
        ? params.filters?.agreedServiceId
        : undefined,
    'filter[addressBookEntryId]': params.filters?.addressBookEntryId,
    'filter[addressBookEntryName]': params.filters?.addressBookEntryName,
    'page[limit]': params.limit,
    'page[offset]': params.offset,
    sort: params.sort,
  };
}

export function mapRcspSearchResponse(response: RcspSearchResponse): PaginatedItems<Rcsp> {
  return mapPaginatedResponse(item => item, response);
}

export function mapRcspToFormData(rcsp: Rcsp): RcspFormData {
  return {
    addressBookEntryId: rcsp.addressBookEntryId,
    agreedServiceId: rcsp.agreedServiceId,
    carrierChargeAccount: rcsp.carrierChargeAccount,
  };
}

export function mapRcspFormDataToCreateRequest(
  formData: RcspFormData,
  siteId: UUID,
): operations['createReceiverCarrierSelectionPolicy']['requestBody']['content']['application/json'] {
  if (!siteId) throw new MappingError('siteId is required');
  if (!formData.addressBookEntryId) throw new MappingError('addressBookEntryId is required');
  if (!formData.agreedServiceId) throw new MappingError('agreedServiceId is required');

  return {
    data: {
      siteId,
      addressBookEntryId: formData.addressBookEntryId,
      agreedServiceId: formData.agreedServiceId,
      carrierChargeAccount: formData.carrierChargeAccount,
    },
  };
}

export function mapRcspFormDataToUpdateRequest(
  formData: RcspFormData,
): operations['updateReceiverCarrierSelectionPolicy']['requestBody']['content']['application/json'] {
  if (!formData.addressBookEntryId) throw new MappingError('addressBookEntryId is required');
  if (!formData.agreedServiceId) throw new MappingError('agreedServiceId is required');

  return {
    data: {
      addressBookEntryId: formData.addressBookEntryId,
      agreedServiceId: formData.agreedServiceId,
      carrierChargeAccount: formData.carrierChargeAccount,
    },
  };
}

/**
 * If we receive a 409 error from the back-end, we need to tell the user you can only have one policy per
 * address book entry
 */
export function mapConflictAsValidationError(error: ApiClientError): ApiClientError {
  if (error.status === 409) {
    const validationError = new ValidationError(error);
    const apiError = {
      source: { pointer: '/data/addressBookEntryId' },
      detail: 'Must select an address with no preferences',
    };
    validationError.apiErrors = [apiError];
    validationError.apiError = apiError;
    return validationError;
  }
  return error;
}
