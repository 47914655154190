import omit from 'lodash/omit';
import { shake } from 'radash';
import { Interval } from '@/shared/DateTime';
import { instantFromDateAndTime } from '@/shared/DateTime/mappers';
import { quoteFactory, type QuoteSet } from '@/shared/models';
import ConsignmentFormData from '@/shared/models/Consignment/ConsignmentFormData';
import ConsignmentFormSupplementalData from '@/shared/models/Consignment/ConsignmentFormSupplementalData';
import { removeEmptyEvaluations } from '@/shared/models/Quote/helpers';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/quote-sets.schema';
import { Temporal } from '@js-temporal/polyfill';

type CreateQuoteSetRequest = operations['createQuoteSet']['requestBody']['content']['application/json'];
export const consignmentToCreateQuoteSetRequest = (
  consignment: ConsignmentFormData,
  supplementalData?: ConsignmentFormSupplementalData,
): CreateQuoteSetRequest => {
  const siteId = supplementalData?.siteId;
  const consignmentId = supplementalData?.consignmentId;
  const senderAddressId = consignment.sender.location.addressId || consignment.sender.location.address?.id;
  const receiverAddressId = consignment.receiver.location.addressId || consignment.receiver.location.address?.id;

  if (!siteId) throw new MappingError('siteId is required');
  if (!senderAddressId) throw new MappingError('sender addressId is required');
  if (!receiverAddressId) throw new MappingError('receiver addressId is required');

  const isSenderResidential = consignment.receiver.location.residential;

  const createRequest: CreateQuoteSetRequest = {
    data: {
      dispatchDate: consignment.dispatchDate!.toString(),
      lineItems: consignment.lineItems.map((lineItem): components['schemas']['LineItem'] => {
        if (!lineItem.description) throw new MappingError('packaging is required');
        if (!lineItem.height) throw new MappingError('height is required');
        if (!lineItem.length) throw new MappingError('length is required');
        if (!lineItem.packagingType) throw new MappingError('packagingType is required');
        if (!lineItem.weight) throw new MappingError('weight is required');
        if (!lineItem.width) throw new MappingError('width is required');

        return {
          description: lineItem.description,
          height: Math.round(lineItem.height),
          reference: lineItem.reference,
          length: Math.round(lineItem.length),
          packagingType: lineItem.packagingType,
          quantity: Math.round(lineItem.quantity),
          weight: Math.round(lineItem.weight),
          width: Math.round(lineItem.width),
        };
      }),
      receiver: {
        addressId: receiverAddressId,
        addressBookEntryId: consignment.receiver.addressBookEntryId,
        residential: isSenderResidential,
      },
      sender: { addressId: senderAddressId },
      siteId,
      consignmentId,
    },
  };

  if (consignment.lineItems.some(lineItem => lineItem.dangerousGoods && lineItem.dangerousGoods.length > 0)) {
    createRequest.data.hasDangerousGoods = true;
  }

  const receiverDeliveryTimeSlot = consignment.receiverDeliveryTimeSlot;
  if (receiverDeliveryTimeSlot?.requiresDTS) {
    const receiverAddress = consignment.receiver.location.address;

    if (!receiverDeliveryTimeSlot?.slot?.dateRange?.length) {
      throw new MappingError('DeliveryTimeSlot DateRange is required', consignmentId);
    }
    if (!receiverDeliveryTimeSlot?.slot?.timeRange?.start)
      throw new MappingError('DeliveryTimeSlot.timeRange.start Date[0] is required');
    if (!receiverDeliveryTimeSlot?.slot?.timeRange?.end)
      throw new MappingError('DeliveryTimeSlot.timeRange.end Date[0] is required');
    if (!receiverAddress?.timeZone) throw new MappingError('receiver.address.timezone is required');

    createRequest.data.receiver.deliveryTimeSlot = {
      window: Interval.from({
        start: instantFromDateAndTime(
          receiverDeliveryTimeSlot?.slot?.dateRange[0],
          receiverDeliveryTimeSlot?.slot?.timeRange.start,
          receiverAddress.timeZone,
        ),
        end: instantFromDateAndTime(
          receiverDeliveryTimeSlot?.slot?.dateRange[0],
          receiverDeliveryTimeSlot?.slot?.timeRange?.end,
          receiverAddress.timeZone,
        ),
      }).toString(),
      recurrences: receiverDeliveryTimeSlot?.slot?.dateRange[1]
        ? receiverDeliveryTimeSlot?.slot?.dateRange[0].until(receiverDeliveryTimeSlot.slot?.dateRange[1]).days
        : 0,
    };
  }

  return createRequest;
};

export const clientQuoteSetToQuoteSet = (
  quoteSet: components['schemas']['QuoteSet'],
  consignment: ConsignmentFormData,
): QuoteSet => ({
  id: quoteSet.id,
  quotes: quoteSet.quotes.map(quote => {
    const evaluation = {
      ...omit(quote.evaluation, ['invalidDeliveryTimeSlotStartDate']),
      ...(quote.evaluation?.invalidDeliveryTimeSlotStartDate?.earliestPermittedDate
        ? {
            invalidDeliveryTimeSlotStartDate: {
              earliestPermittedDate: Temporal.PlainDate.from(
                quote.evaluation.invalidDeliveryTimeSlotStartDate.earliestPermittedDate,
              ),
              dateRange: consignment.receiverDeliveryTimeSlot?.slot?.dateRange,
            },
          }
        : {}),
    };

    return quoteFactory.create({
      id: quote.id,
      agreedServiceId: quote.agreedServiceId,
      carrierId: quote.carrierId,
      carrierServiceId: quote.carrierServiceId,
      quoteSetId: quote.quoteSetId,
      eta: quote.etaDate,
      selectable: quote.isSelectable,
      recommended: quote.isSelectable && !Object.keys(removeEmptyEvaluations(evaluation)).length,
      evaluation: shake(quote.evaluation),
      price: {
        isMasked: quote.price.isMasked,
        rank: quote.price.rank,
        cost: quote.price.cost
          ? {
              chargesBreakdown: quote.price.cost.chargesBreakdown || [],
              currency: quote.price.cost.currency,
              freight: quote.price.cost.freight || 0,
              fees: quote.price.cost.charges || 0,
              net: quote.price.cost.net || 0,
              tax: quote.price.cost.tax || 0,
              total: quote.price.cost.total,
            }
          : undefined,
      },
      payerAccount: quote.payerAccount,
      createdAt: quote.createdAt,
    });
  }),
  createdAt: quoteSet.createdAt,
});
