import { name as Address, store as AddressStore } from '@/app/modules/Address';
import { name as Auth, store as AuthStore } from '@/app/modules/Auth';
import { name as ConsignmentTrack, store as ConsignmentTrackStore } from '@/app/modules/ConsignmentTrack';
import { name as GlobalSearch, store as GlobalSearchStore } from '@/app/modules/GlobalSearch';
import { name as Org, store as OrgStore } from '@/app/modules/Org';

const modules = {
  [Auth]: {
    store: AuthStore,
  },
  [ConsignmentTrack]: {
    store: ConsignmentTrackStore,
  },
  [Org]: {
    store: OrgStore,
  },
  [Address]: {
    store: AddressStore,
  },
  [GlobalSearch]: {
    store: GlobalSearchStore,
  },
};

export default modules;
