import config from '@/config';

import { routes as AddressBookRoutes } from '@/app/modules/AddressBook';
import { contextPrefix } from '@/shared/router/helpers';
import autoprintRoutes from '@/app/modules/AutoPrint/routes';
import { routes as ConsignmentRoutes } from '@/app/modules/Consignment';
import dangerousGoodsPresetRoutes from '@/app/modules/DangerousGoods/routes';
import estimateRoutes from '@/app/modules/Estimate/routes';
import { routes as ManifestRoutes } from '@/app/modules/Manifest';
import OrderRoutes from '@/app/modules/Order/routes';
import { routes as PickupRoutes } from '@/app/modules/Pickup';
import ShippingItemPresetRoutes from '@/app/modules/ShippingItemPreset/routes';
import SiteLayout from '@/app/modules/Site/views/SiteLayout.vue';

import DefaultSiteRedirect from './views/DefaultSiteRedirect';
import SitePreferencesRoutes from '@/app/modules/SitePreferences/routes';

const SiteRoutes = [
  {
    name: 'site.default',
    path: '/site',
    component: DefaultSiteRedirect,
    meta: {
      enableFullStory: true,
      requiresAuthentication: true,
    },
  },
  {
    name: 'site',
    path: contextPrefix,
    redirect: { name: config.defaultRoute },
    component: SiteLayout,
    meta: {
      enableFullStory: true,
      requiresAuthentication: true,
    },
    children: [
      // /address-book/*
      ...AddressBookRoutes,

      // /consignment/*
      ...ConsignmentRoutes,

      // /estimate/*
      ...estimateRoutes,

      // /manifest/*
      ...ManifestRoutes,

      // /pickup/*
      ...PickupRoutes,

      // /shipping-item-presets/*
      ...ShippingItemPresetRoutes,

      // /autoprint/*
      ...autoprintRoutes,

      // /dangerous-goods-presets/*
      ...dangerousGoodsPresetRoutes,

      // /orders/*
      ...OrderRoutes,

      // /site-preferences/*
      ...SitePreferencesRoutes,
    ],
  },
];

export default SiteRoutes;
