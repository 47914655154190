<template>
  <div class="list-box-drop-down" :class="classes">
    <slot name="trigger"></slot>
    <Transition name="drop-down">
      <div v-show="open" class="drop-down">
        <slot name="drop-down"></slot>
      </div>
    </Transition>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
  });

  const classes = computed(() => ({
    'is-open': props.open,
    'is-left': props.left,
    'is-top': props.top,
  }));
</script>

<style scoped lang="scss">
  .list-box-drop-down {
    display: inline-block;
    position: relative;
  }

  .drop-down {
    position: absolute;
    z-index: 10;
    top: 100%;
    padding-top: var(--space-xs);

    .is-top & {
      top: auto;
      bottom: 100%;
      padding-top: 0;
      padding-bottom: var(--space-xs);
    }

    .is-left & {
      right: 0;
    }
  }

  .drop-down-enter-active,
  .drop-down-leave-active {
    transition:
      opacity 150ms ease,
      transform 150ms ease;
    transform: translateY(0);
    pointer-events: auto;
  }

  .drop-down-enter-from,
  .drop-down-leave-to {
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;

    .is-top & {
      transform: translateY(10px);
    }
  }
</style>
