import { computed, MaybeRef, provide, readonly, Ref, ref } from 'vue';

import contextInjectionKey from '@/app/context/contextInjection';

import type { Context } from './types';

const createContext = () => {
  const defaultContext: Context = {
    site: undefined,
    org: undefined,
  };

  const contextStore = ref({ ...defaultContext }) as Ref<Context>;
  const context = readonly(contextStore) as Readonly<typeof contextStore>;

  const setContext = (newContext: MaybeRef<Context>) => {
    // TODO explore how to make TS happy setting a computed into a ref
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    contextStore.value = newContext;
  };

  const clearContext = () => {
    setContext({ ...defaultContext });
  };

  // context can have an org without a site, but not a site without an org
  const hasContext = computed(() => !!context.value.org);

  provide(contextInjectionKey, context);

  return {
    context,
    hasContext,
    setContext,
    clearContext,
  };
};

export default createContext;
