import PickupServiceClient from '@/shared/services/duke/PickupServicesClient';

export { dangerousGoodsPresetsService, senderPoliciesService } from './orgServices';

export {
  autoprintService,
  consignmentService,
  consolidationService,
  customFieldsService,
  estimateService,
  manifestService,
  orderService,
  pickupService,
  quoteService,
  trackingService,
} from './senderServices';
export { addressBookService } from './addressBookServices';

// TODO refactor to pass APIClient
const dukeBaseUrl = import.meta.env.GEPPETTO_SERVICE_DUKE_URL;
export const pickupServicesService = new PickupServiceClient(dukeBaseUrl);
