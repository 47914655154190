import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';
import userMonitor from '@/app/instrumentation/userMonitor';

import { ConsignmentInstrumentation } from './types';
import { Quote } from '@/shared/models';

instrumentation.on(ConsignmentInstrumentation.QUOTES_REQUESTED, ({ quotes, quoteSetId }) => {
  /** @type {Quote[]} */
  const filteredQuotes = Array.isArray(quotes) ? quotes.filter(q => !!q) : []; // ensure we have an array of not-undefined values

  const eventContext = {
    quotes: {
      // quotes matching/recommended by default
      // @context.quotes.quotes_presented (measure)
      quotes_presented: filteredQuotes.filter(quote => quote.price.cost !== undefined && quote.selectable).length,

      // @context.quotes.quotes_unrated (measure)
      quotes_unrated: filteredQuotes.filter(quote => quote.evaluation?.unavailablePricing).length,

      // @context.quotes.quotes_unselectable (measure)
      quotes_unselectable: filteredQuotes.filter(quote => !quote.selectable).length,

      // if any quote has the 'unsupportedByReceiver' evaluation, then an RCSP policy is in play.
      // @context.quotes.rcsp (facet)
      rcsp: quotes.some((quote: Quote) => quote.evaluation?.unsupportedByReceiver),

      // @context.quotes.quotes_total (measure)
      quotes_total: filteredQuotes.length,
      quoteSetId,
    },
  };

  userMonitor.event('quotes requested', eventContext);
});

instrumentation.on(ConsignmentInstrumentation.QUOTE_SELECTION_FINALISED, ({ duration, quickOption, quoteSetId }) => {
  userMonitor.event('quote selected', {
    duration,
    quotes: {
      cheapest_quick_selection: quickOption,
      quoteSetId,
    },
  });
});

instrumentation.on(ConsignmentInstrumentation.QUOTE_SELECTED, ({ quote }) => {
  if (quote.evaluation.unavailablePricing) {
    FullStory.event('consignment quote without estimate selected', {});
  }
});
