import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import { Order, OrderSummary } from '@/shared/models/Order/Order';
import ApiClient, { ApiClientRequestConfig } from '@/shared/services/api-client';
import { formatSearchParams } from '@/shared/services/helpers';
import { paths } from '@/shared/services/schema/geppetto-sender-app/orders.schema';

import { mapOrdersSearchResults } from './mappers/orderMappers';

export interface PresetSearchParams {
  limit?: number;
  offset?: number;
  sort?: string;
  availableAtSiteId: string[];
  search?: string | null;
}

export default class OrdersClient {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  public async searchOrders(
    { availableAtSiteId, limit, offset, sort = 'orderNumber', search }: PresetSearchParams,
    config?: ApiClientRequestConfig,
  ): Promise<PaginatedItems<OrderSummary>> {
    const params = formatSearchParams({
      limit,
      offset,
      sort,
      search,
    });

    const response = await this.apiClient.query<
      paths['/orders']['get']['responses']['200']['content']['application/json']
    >('/orders', {
      ...config,
      params: { ...params, availableAtSiteId },
    });

    logger.debug('[Orders] searched', {}, { params, response });

    return mapOrdersSearchResults(response.data);
  }

  public async fetch({ id }: { id: UUID }, config?: ApiClientRequestConfig): Promise<Order> {
    const response = await this.apiClient.get<
      paths['/orders/{id}']['get']['responses']['200']['content']['application/json']
    >(`/orders/${id}`, { ...config });

    logger.debug('[Orders] fetch', {}, { orderId: id, response });

    return response.data.data;
  }

  public async delete(id: UUID) {
    const response = await this.apiClient.delete<paths['/orders/{id}']['delete']['responses']['204']>(`/orders/${id}`);

    logger.debug('[Orders] Order deleted', {}, { estimateId: id, response });
  }
}
