<template>
  <div class="site-context-dropdown">
    <Tooltip
      v-on-outside:click="hideTooltip"
      position="is-right"
      :active="showTooltip"
      :always="toolTipIsManual"
      class="highlight-context"
    >
      <ContextDropDown
        data-testref="app-header-site"
        title="Site"
        :options="options"
        :create-link="createLink"
        :selected="selected"
        overview="Sites overview"
        sensitive
        :class="showOutline ? 'has-outline' : ''"
      >
        <template #item="{ option, filter }">
          <TextHighlight :text="option.name" :highlight="filter" />
          <SvgIcon
            :svg="IconStarOutline"
            :class="isDefaultContext(option.id) ? 'is-default' : ''"
            class="make-default"
            size="small"
            @click.prevent.stop="setDefaultContextToSite(option.id)"
          />
        </template>
      </ContextDropDown>
      <template #content> Site has changed </template>
    </Tooltip>
  </div>
</template>

<script setup>
  import { useTimeoutFn } from '@vueuse/core';
  import { computed, ref, watch } from 'vue';

  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';

  import Tooltip from '@/shared/components/Tooltip.vue';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import IconStarOutline from '@/shared/icons/svgs/rating-star.svg';

  import { useNotify } from '@/shared/notify';
  import vOnOutside from '@/shared/on-outside';

  import TextHighlight from '@/shared/TextHighlight/TextHighlight.vue';

  import App from '@/app/store/types';
  import Org from '@/app/modules/Org/store/types';
  import { useDefaultContext } from '@/app/modules/Site/behaviours/defaultPage';
  import ContextDropDown from '@/app/modules/Site/components/ContextDropDown.vue';

  const props = defineProps({
    options: {
      type: Array,
      default: () => [],
    },
    orgId: {
      type: String,
      default: '',
    },
    createLink: {
      type: Function,
      required: true,
    },
    selected: {
      type: String,
      default: undefined,
    },
  });

  const store = useStore();
  const { getDefaultContext, setDefaultContext, clearDefaultContext } = useDefaultContext();

  const siteHasChanged = computed(() => store.getters[App.getters.userHasChangedSite]);
  const showTooltip = ref(false);
  const toolTipIsManual = ref(true);
  const showOutline = ref(false);

  const hideTooltip = () => {
    if (!showTooltip.value) return;
    toolTipIsManual.value = false;
  };

  const route = useRoute();
  const { toast } = useNotify();

  const { start: startTimeout } = useTimeoutFn(
    () => {
      hideTooltip();
    },
    10000,
    { immediate: false },
  );

  watch(
    [route],
    () => {
      if (siteHasChanged.value) {
        showTooltip.value = true;
        showOutline.value = true;
        toolTipIsManual.value = true;
        store.dispatch(App.actions.siteChanged, false);
        startTimeout();
      } else {
        showTooltip.value = false;
        showOutline.value = false;
        toolTipIsManual.value = true;
      }
    },
    { immediate: true },
  );

  const defaultContext = ref(getDefaultContext());
  const setDefaultContextToSite = siteId => {
    const contextType = siteId ? 'site' : 'org';
    const contextId = siteId || props.orgId;
    let message = 'All sites set as default';
    setDefaultContext({ type: contextType, id: contextId });

    if (contextType === defaultContext.value.type && contextId === defaultContext.value.id) {
      clearDefaultContext();
    } else {
      setDefaultContext({ type: contextType, id: contextId });

      if (contextType === 'site') {
        message = `${store.getters[Org.getters.site](contextId).name} set as default`;
      }
      toast({ message, type: 'success', sensitive: true });
    }
    defaultContext.value = getDefaultContext();
  };

  const isDefaultContext = siteId => {
    if (!siteId) {
      if (defaultContext.value.type === 'org' && defaultContext.value.id === props.orgId) return true;
      return false;
    }
    if (defaultContext.value.type === 'site' && defaultContext.value.id === siteId) return true;
    return false;
  };
</script>

<style scoped lang="scss">
  .has-outline {
    :deep(.header-button) {
      border-color: var(--warning-100);
    }
  }

  .make-default {
    margin-left: auto;
    color: transparent;
    transition: color 0.2s ease;
    fill: none;

    .list-box-item:hover & {
      color: var(--brand-20);

      &:hover {
        color: var(--brand-30);
      }
    }

    &.is-default {
      fill: currentColor;
      color: var(--brand-20);
    }
  }
</style>
