import { h } from 'vue';
import { useRouter } from 'vue-router';

import { reloadCallback, type ErrorHandlingConfig, sharedDisplayHandlers } from '@/shared/errorHandling/config';
import { errorIsCausedBy } from '@/shared/errorHandling/helpers';
import SiteIdLimitError from '@/shared/services/sender/errors/SiteIdLimitError';

import ErrorFallback from '@/app/ErrorFallback.vue';
import { AgreedServiceNotFoundError } from '@/shared/models/Quote/QuoteSetWithServices';

export const appErrorHandlingConfig: ErrorHandlingConfig = {
  fallbackComponent: ErrorFallback,
  errorDisplayHandlers: [
    ...sharedDisplayHandlers,
    // first match will be displayed
    {
      check: error => errorIsCausedBy(error, AgreedServiceNotFoundError),
      component: () => ({
        setup() {
          const router = useRouter();
          return () =>
            h('p', [
              'Unable to retrieve pricing for this consignment. ',
              h('a', { onClick: reloadCallback(router) }, 'Refresh the page'),
              ' or contact efm for further assistance',
            ]);
        },
      }),
    },
    {
      check: error => error instanceof SiteIdLimitError,
      component: error => ({
        setup() {
          return () => [
            h('p', `Sites selected exceeds maximum${error instanceof SiteIdLimitError ? ` of ${error.maximum}` : ''}.`),
            h('p', 'Modify your selection to see different results.'),
          ];
        },
      }),
    },
  ],
};
