import FullStory from '@/shared/FullStory';
import VueInstrumentation from '@/shared/instrumentation/VueInstrumentation';

import AddressBookInstrumentationTypes from '@/app/modules/AddressBook/instrumentation/types';
import userMonitor from '@/app/instrumentation/userMonitor';

export function registerHandlers(instrumentation: VueInstrumentation) {
  instrumentation.on(AddressBookInstrumentationTypes.VALIDATION_ERROR, () => {
    userMonitor.event('address book validation error', {});

    FullStory.event('address book validation error', {});
  });

  instrumentation.on(AddressBookInstrumentationTypes.ENTRY_OPENED, ({ addressBookEntry }) => {
    userMonitor.event('address book entry opened', {
      address_book_entry: {
        id: addressBookEntry?.id,
      },
    });

    FullStory.event('address book entry opened', {
      addressBookEntryId: addressBookEntry?.id,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.ENTRY_SELECTED, ({ addressBookEntry, userInput }) => {
    userMonitor.event('address book entry selected', {
      address_book_entry: {
        id: addressBookEntry.id,
      },
      // @context.user_input_length
      user_input_length: userInput.length,
    });

    FullStory.event(
      'address book entry selected',
      {
        addressBookEntryId: addressBookEntry.id,
        userInputLength: userInput.length,
      },
      {
        userInputLength: 'int',
      },
    );
  });

  instrumentation.on(AddressBookInstrumentationTypes.ENTRY_CREATED, ({ addressBookEntry }) => {
    userMonitor.event('address book entry created', {
      address_book_entry: {
        id: addressBookEntry.id,
        // @context.address_book_entry.with_quick_access_code
        with_quick_access_code: !!addressBookEntry.quickAccessCode,
      },
    });

    FullStory.event('address book entry created', {
      addressBookEntryId: addressBookEntry.id,
      withQuickAccessCode: !!addressBookEntry.quickAccessCode,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.ENTRY_UPDATED, ({ addressBookEntry }) => {
    userMonitor.event('address book entry updated', {
      address_book_entry: {
        id: addressBookEntry.id,
        // @context.address_book_entry.with_quick_access_code
        with_quick_access_code: !!addressBookEntry.quickAccessCode,
      },
    });

    FullStory.event('address book entry updated', {
      addressBookEntryId: addressBookEntry.id,
      withQuickAccessCode: !!addressBookEntry.quickAccessCode,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.ENTRY_DELETED, ({ addressBookEntry }) => {
    userMonitor.event('address book entry deleted', {
      address_book_entry: {
        id: addressBookEntry.id,
      },
    });

    FullStory.event('address book entry deleted', {
      addressBookEntryId: addressBookEntry.id,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.SUGGESTION_DELETED, ({ addressBookEntry }) => {
    userMonitor.event('address book entry deleted from suggestion', {
      address_book_entry: {
        id: addressBookEntry.id,
      },
    });

    FullStory.event('address book entry deleted from suggestion', {
      addressBookEntryId: addressBookEntry.id,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.BULK_ENTRY_DELETED, ({ addressBookEntryIds }) => {
    userMonitor.event('address book entry bulk deleted', {
      // @context.count (measure)
      count: addressBookEntryIds.length,
    });

    FullStory.event('address book entry bulk deleted', {
      addressBookEntryIds,
    });
  });

  instrumentation.on(
    AddressBookInstrumentationTypes.BULK_ENTRY_IMPORTED,
    ({ total, successful, validationErrors, duration }) => {
      userMonitor.event('address book entry bulk imported', {
        total, // Measure: @context.total
        imported: successful, // Measure: @context.imported
        validation_errors: validationErrors, // Measure: @context.validation_errors
        duration,
      });

      FullStory.event(
        'address book entry bulk imported',
        {
          total,
          imported: successful,
          validationErrors,
          duration,
        },
        {
          total: 'int',
          imported: 'int',
          validationErrors: 'int',
          duration: 'int',
        },
      );
    },
  );

  instrumentation.on(AddressBookInstrumentationTypes.CONTACT_SELECTED, ({ addressee, contact, userInput }) => {
    userMonitor.event('address book contact selected', {
      address_book_entry: {
        id: addressee.addressBookEntryId,
        contact: {
          id: contact.id,
        },
      },
      // @context.user_input_length
      user_input_length: (userInput || '').length,
    });

    FullStory.event(
      'address book contact selected',
      {
        addressBookEntryId: addressee.addressBookEntryId,
        addressBookContactId: contact.id,
        userInputLength: (userInput || '').length,
      },
      {
        userInputLength: 'int',
      },
    );
  });

  instrumentation.on(AddressBookInstrumentationTypes.CONTACT_CREATED, ({ addressBookEntry, contact }) => {
    userMonitor.event('address book contact created', {
      address_book_entry: {
        id: addressBookEntry.id,
        contact: {
          id: contact.id,
        },
      },
    });

    FullStory.event('address book contact created', {
      addressBookEntryId: addressBookEntry.id,
      addressBookContactId: contact.id,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.CONTACT_UPDATED, ({ addressBookEntry, contact }) => {
    userMonitor.event('address book contact updated', {
      address_book_entry: {
        id: addressBookEntry.id,
        contact: {
          id: contact.id,
        },
      },
    });

    FullStory.event('address book contact updated', {
      addressBookEntryId: addressBookEntry.id,
      addressBookContactId: contact.id,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.CONTACT_DELETED, ({ addressBookEntry, contact }) => {
    userMonitor.event('address book contact deleted', {
      address_book_entry: {
        id: addressBookEntry.id,
        contact: {
          id: contact.id,
        },
      },
    });

    FullStory.event('address book contact deleted', {
      addressBookEntryId: addressBookEntry.id,
      addressBookContactId: contact.id,
    });
  });

  instrumentation.on(AddressBookInstrumentationTypes.USER_LOCATION_SELECTED, () => {
    userMonitor.event('address book duplicate suggestion dismissed', {});
    FullStory.event('address book duplicate suggestion dismissed');
  });

  instrumentation.on(AddressBookInstrumentationTypes.DUPLICATE_SUGGESTION_SELECTED, duplicate => {
    userMonitor.event('address book duplicate suggestion selected', {
      addressBookEntry: {
        id: duplicate.id,
      },
    });

    FullStory.event('address book duplicate suggestion selected', {
      addressBookEntryId: duplicate.id,
    });
  });
}
