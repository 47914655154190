import { Temporal } from '@js-temporal/polyfill';

import { DateRange } from '@/shared/DateTime';

import Destination from '../Destination';
import { WithRequired } from '@/shared/types/utility';

interface ChargesBreakdown {
  name: string;
  cost: Cents;
}

/**
 * @example {
 *   "Carton": 25,
 *   "Pallet": 120
 * }
 */
export type PackagingTypeConstraints = {
  [key: string]: number;
};

/**
 * Represents the pricing details of a quote.
 */
export interface QuotePrice {
  /**
   * Indicates whether the pricing information is hidden from the user.
   * - If `true`, the `cost` field will be `undefined`.
   */
  isMasked: boolean;
  /**
   * The ranking of the total price of a quote, which determines its position relative to others.
   * - A `null` value means pricing is not available.
   */
  rank: number | null;
  /**
   * The cost breakdown of the quote. This field is optional and will be present
   * only if `isMasked` is `false` and pricing was available.
   */
  cost?: {
    currency: string;
    chargesBreakdown: ChargesBreakdown[];
    fees: Cents;
    freight: Cents;
    net: Cents;
    tax: Cents;
    total: Cents;
  };
}

/**
 * Represents the data structure of a quote in the app.
 * Shared model used for estimates and consignments.
 */
interface Quote {
  id: UUID;
  agreedServiceId: string;
  carrierId: string;
  carrierServiceId: string;
  createdAt: string; // DateTime
  eta?: string; // Date
  evaluation: {
    unsupportedPackagingTypes?: string[];
    unavailablePricing?: { from: Destination; to: Destination };
    unsupportedFromLocality?: Destination;
    unsupportedToLocality?: Destination;
    unsupportedDeliveryTimeSlots?: boolean;
    invalidDeliveryTimeSlotStartDate?: { earliestPermittedDate: Temporal.PlainDate; dateRange?: DateRange };
    notCheapest?: boolean;
    unsupportedByReceiver?: boolean;
    dangerousGoods?: {
      carrierAuthorityDenied?: boolean;
      notAllowedByCarrier?: boolean;
    };
    failedConstraints?: {
      consignmentMaxItemCount?: number;
      consignmentMaxDeadWeight?: number;
      consignmentMaxVolume?: number;
      consignmentIntrastateOnly?: boolean;
      itemMaxLength?: number;
      itemMaxWidth?: number;
      itemMaxHeight?: number;
      itemMaxDeadWeight?: number;
      itemMaxVolume?: number;
      packagingTypeMaxLength?: PackagingTypeConstraints;
      packagingTypeMaxWidth?: PackagingTypeConstraints;
      packagingTypeMaxHeight?: PackagingTypeConstraints;
      packagingTypeMaxDeadWeight?: PackagingTypeConstraints;
      packagingTypeMaxVolume?: PackagingTypeConstraints;
    };
  };
  quoteSetId: string;
  selectable: boolean;
  recommended: boolean;
  /**
   * For RCSP - this receiver / carrier combination requires a specific payer account
   */
  payerAccount?: string;

  price: QuotePrice;
}

/**
 * Utility model, extension of a quote that contains cost details.
 * - can be used after filtering quotes without `price.cost`
 */
export type QuoteWithPrices = Quote & {
  price: WithRequired<QuotePrice, 'cost'>;
};

export const isQuoteWithPrices = (quote: Quote): quote is QuoteWithPrices => !!quote.price.cost;

const create = ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  createdAt,
  eta,
  quoteSetId,
  evaluation,
  selectable,
  recommended,
  price,
  payerAccount,
}: Quote): Quote => ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  createdAt,
  eta,
  quoteSetId,
  evaluation,
  selectable,
  recommended,
  price,
  payerAccount,
});

export const quoteFactory = {
  create,
};

export default Quote;
