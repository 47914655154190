<template>
  <BasePill :label="status" :color="color" />
</template>

<script setup>
  import { computed } from 'vue';

  import BasePill from '@/shared/components/BasePill.vue';

  import { getConsignmentStatus } from '@/app/modules/Consignment/helpers';

  const props = defineProps({
    consignment: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  });

  const status = computed(() => {
    if (props.loading) return 'Loading status';
    return getConsignmentStatus({ consignment: props.consignment }).label;
  });

  const color = computed(() => {
    if (props.loading) return 'chrome';
    return getConsignmentStatus({ consignment: props.consignment }).color;
  });
</script>
