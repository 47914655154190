<template>
  <div class="modal-background"></div>
  <div
    v-on-outside:click="quickCloseModal"
    v-bind="$attrs"
    class="modal-card"
    :class="modalClasses"
    :data-testref="dataTestref"
  >
    <div v-if="title || quickClose" class="modal-card-head">
      <div v-sensitive class="modal-card-title">{{ title }}</div>
      <ButtonClose v-if="quickClose" data-testref="modal-close" @click="quickCloseModal" />
    </div>
    <section class="modal-card-body">
      <slot>
        <!-- Modal content -->
      </slot>
    </section>
    <footer class="modal-card-foot hidden-if-empty">
      <slot name="footer">
        <!-- Modal footer -->
      </slot>
    </footer>
  </div>
</template>

<script setup lang="ts">
  import { useEventListener } from '@vueuse/core';
  import { computed } from 'vue';

  import ButtonClose from '@/shared/components/ButtonClose.vue';
  import { useModal } from '@/shared/modals/modalManager';
  import vOnOutside from '@/shared/on-outside';
  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';
  import { onBeforeRouteLeave } from 'vue-router';

  defineOptions({
    inheritAttrs: false,
  });

  const props = withDefaults(
    defineProps<{
      title?: string;
      sensitive?: boolean;
      quickClose?: boolean;
      dataTestref?: string;
      size?: 'small' | 'medium' | 'large';
      onBeforeClose?: () => Promise<boolean | void>;
      closeOnRouteLeave?: boolean;
    }>(),
    {
      quickClose: true,
      title: undefined,
      sensitive: false,
      dataTestref: undefined,
      size: 'medium',
      onBeforeClose: undefined,
      closeOnRouteLeave: true,
    },
  );

  const emit = defineEmits<{
    close: [];
  }>();

  const modal = useModal();
  const vSensitive = createSensitiveDirective(() => props.sensitive);

  const modalClasses = computed(() => ({
    'is-large': props.size === 'large',
    'is-small': props.size === 'small',
    'is-active': modal.isActive,
  }));

  const closeModal = async () => {
    if ((await props.onBeforeClose?.()) === false) return;
    emit('close');
    modal.close();
  };

  const quickCloseModal = async () => {
    if (props.quickClose && modal.isActive) {
      await closeModal();
    }
  };

  onBeforeRouteLeave(async () => {
    if (props.closeOnRouteLeave) {
      await closeModal();
    }
  });

  useEventListener('keyup', e => {
    if (e.code === 'Escape') quickCloseModal();
  });
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: var(--radius-s);

    .modal-card-title {
      line-height: 1.5;
    }

    &.is-large {
      width: 900px;
    }

    &.is-small {
      width: 30rem;
    }
  }

  .modal-card-foot {
    display: block;

    &:empty {
      display: none;
    }
  }

  .modal-background {
    // default bulma modal-background style stacks when multiple modals are open
    background-color: transparent;

    // only add a background colour for the modal-background before the single active modal
    // this prevents multiple modal-backgrounds stacking, causing progressively darker backgrounds
    &:has(+ .modal-card.is-active) {
      background-color: rgba(10, 10, 10, 0.6);
    }
  }
</style>
